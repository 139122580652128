import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>Contact Us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="dashboard">
                        <div className="container">
                            Coming Soon ...
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default ContactUs;
