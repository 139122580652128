import React from "react";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <Header connect={false}/>
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div id="carouselHero" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="row">
                                                <div className="col-md-6 order-md-2">
                                                    <img src="assets/images/ban_01.png" alt="" />
                                                </div>
                                                <div className="col-md-6 order-md-1">
                                                    <h1>
                                                        <small></small><br />
                                                        WHY US ?
                                                        {/* <span>?</span> */}
                                                    </h1>
                                                    <p>
                                                        We offer expertise in customized surveys, unbiased research, cutting-edge methodologies, comprehensive data analysis, privacy and security measures, and prompt support. By choosing us, you can gain valuable insights into the Web3 landscape, enhance user experiences, and stay ahead in the ever-evolving world of decentralized technology.
                                                    </p>
                                                    <a href="#" className="btn btn-primary py-2 px-4">Join Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <img src="assets/images/ban_02.png" alt="" />
                                                </div>
                                                <div className="col-md-6">
                                                    <h2><small>Become A</small><br />
                                                        SURVEYDEXER</h2>
                                                    <form>
                                                        <div className="mb-3">
                                                            <input className="form-control" type="text" placeholder="Enter your Full name" />
                                                        </div>
                                                        <div className="mb-4">
                                                            <input className="form-control" type="text" placeholder="Enter your Email" />
                                                        </div>
                                                        <button type="submit" className="btn btn-primary">SIGN UP</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="community">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col d-none d-md-block">
                                    <img src="assets/images/emt_art.png" alt="" />
                                </div>
                                <div className="col">
                                    <h2><span>Join a community</span><br />
                                        surveydexer</h2>
                                    <hr className="d-none d-md-block" />
                                    <div className="d-sm-block d-md-none">
                                        <img src="assets/images/emt_art.png" alt="" />
                                    </div>
                                    <p>voice to make a better community<br /> and enjoy rewards from answering</p>
                                    <h3>Our surveys</h3>
                                    <a href="#" className="btn btn-learn mt-3">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <div className="why">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <h2>Benefit Of Joining Us</h2>
                                    <p>
                                        100% Free: No fees, no catches
                                    </p>
                                    {/* <p>It has survived not only five centuries, but also the leap into electronic typesetting,<br /> remaining essentially unchanged.<br /> It was popularised in the 1960s with the release of Letraset sheets containing</p> */}
                                </div>
                                <div className="col-md-8 mx-auto">
                                    <div className="row">
                                        <div className="col">
                                            <figure><img src="assets/images/wjs_01.png" alt="" /></figure>
                                            {/* <h4>A channel to share real<br /> views on topics that<br /> impact People</h4> */}
                                            <h4>Sign up with no hassle through dapp wallet.</h4>
                                        </div>
                                        <div className="col">
                                            <figure><img src="assets/images/wjs_02.png" alt="" /></figure>
                                            <h4>Earn Reward by doing survey and referral </h4>
                                            {/* <h4>Earn attractive rewards</h4> */}
                                            {/* <p>Share your opinions<br /> and redeem great vouchers<br /> or contribute to a good cause</p> */}
                                        </div>
                                        <div className="col">
                                            <figure><img src="assets/images/wjs_03.png" alt="" /></figure>
                                            <h4>Flexible: Work from home, whenever you like</h4>
                                            {/* <h4>Find out What People Thinks</h4>
                                            <p>DiscoverPeople thoughts<br /> about issues that impact us</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="how">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <h2>How it works</h2>
                                    <p>Take Surveys, Earn Rewards, Convert to USDT. It's that simple!!</p>
                                </div>
                                <div className="col-md-8 mx-auto">
                                    <div className="row">
                                        <div className="col">
                                            <figure><img src="assets/images/user-sign-up.png" alt="" /></figure>
                                            <h4>Step 1</h4>
                                            <p>Join for free! It takes less than a minute</p>
                                        </div>
                                        <div className="col">
                                            <figure><img src="assets/images/web-site.png" alt="" /></figure>
                                            <h4>Step 2</h4>
                                            <p>Participate in interesting survery to get rewards</p>
                                        </div>
                                        <div className="col">
                                            <figure><img src="assets/images/money-bag.png" alt="" /></figure>
                                            <h4>Step 3</h4>
                                            <p>Earn Reward point to exchange for USDT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="do">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col d-none d-md-block">
                                    <img src="assets/images/review.png" alt="" />
                                </div>
                                <div className="col">
                                    <h2>Do Surveys<br />
                                        <span>and make a difference</span></h2>
                                    <div className="d-sm-block d-md-none">
                                        <img src="assets/images/review.png" alt="" />
                                    </div>
                                    <p>
                                        Through impactful work, addressing real-world challenges, empowering others, advancing sustainability, inspiring change and awareness, collaborative partnerships, creating a ripple effect, and experiencing personal fulfilment, you have the opportunity to create a lasting impact and contribute to a better world. Come join us and be a catalyst for positive change!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <div className="rewards">
                            <div className="row align-items-center">
                                <div className="col d-none d-md-block">
                                    <img src="assets/images/rws.png" alt="" />
                                </div>
                                <div className="col">
                                    <h2>Get instant rewards</h2>
                                    <p>Get rewarded with DEX Points for each survey you complete, referral and  redeem them for USDT</p>
                                    <hr className="d-none d-md-block" />
                                    <div className="d-sm-block d-md-none">
                                        <img src="assets/images/rws.png" alt="" />
                                    </div>
                                    <p className="step">To redeem your DEX Points, simply follow the steps below:</p>
                                    <ol>
                                        <li>Login to your Dashboard</li>
                                        <li>Head over to DEX Rewards</li>
                                        <li>Choose Convert to USDT</li>
                                        <li>Withdraw to your DAPP Wallet</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="says">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 mb-4 text-center">
                                    <h2>What Surveysdexers Say</h2>
                                </div>
                                <div className="col">
                                    <div className="box">
                                        <p>I'm extremely impressed with SurveyDex! The platform is user-friendly, and the survey creation process was seamless. The data analysis tools provided valuable insights, and the rewards program was a nice touch. Overall, a fantastic experience! Highly recommend! ⭐⭐⭐⭐⭐</p>
                                        <div className="row gx-3 align-items-center">
                                            <div className="col-2"><img src="assets/images/icn_user.png" alt="" /></div>
                                            <div className="col-10"><h4>******f4cf9</h4></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="box">
                                        <p>Amazing experience with SurveyDex! Their surveys are engaging, and the rewards make it even better. Loved earning rewards while sharing my opinions. Top-notch service! </p>
                                        <div className="row gx-3 align-items-center">
                                            <div className="col-2"><img src="assets/images/icn_user.png" alt="" /></div>
                                            <div className="col-10"><h4>******b5bn7</h4></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="box">
                                        <p>Incredibly happy with SurveyDex!! Their rewarding surveys make participating worthwhile. Quick and easy process with great rewards. Highly recommended!</p>
                                        <div className="row gx-3 align-items-center">
                                            <div className="col-2"><img src="assets/images/icn_user.png" alt="" /></div>
                                            <div className="col-10"><h4>******k6ah1</h4></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-4 text-center">
                                    <a href="#" className="btn btn-primary rounded-pill">See More</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default Home;
