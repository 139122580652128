import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class TermsConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>Terms & Conditions</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="dashboard">
                        <div className="container">
                            <p>
                                Please read these Terms and Conditions ("Terms") carefully before using SurveyDex (the "Service"), operated by SurveyDex("us", "we", or "our").<br />
                                By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Service.
                            </p>
                            <strong>1. Description of Service</strong>
                            <p>SurveyDex is a platform that provides online surveys and data collection services for individuals and organizations seeking to gather feedback and insights from participants ("Users") through various survey instruments. The Service may include features such as survey creation, data analysis tools, and result reporting.</p>

                            <strong>2. User Eligibility</strong>
                            <p>
                                2.1. By using the Service, you represent and warrant that you are of legal age and have the legal capacity to enter into this agreement or that you have obtained consent from a legal guardian to do so.<br />
                                2.2. If you are using the Service on behalf of an organization, you further represent and warrant that you have the authority to bind the organization to these Terms.
                            </p>

                            <strong>3. User Conduct</strong>
                            <p>
                                3.1. Users of SurveyDex must comply with all applicable laws and regulations while using the Service.<br />
                                3.2. Users are solely responsible for the content they create, upload, or share through the Service. Content must not violate the rights of any third party or contain harmful, offensive, or misleading information.<br />
                                3.3. Users are prohibited from attempting to gain unauthorized access to any part of the Service, interfering with its functionality, or disrupting the experience of other Users.
                            </p>
                            <strong>4. Intellectual Property</strong>
                            <p>
                                4.1. All intellectual property rights related to the Service, including but not limited to trademarks, logos, designs, and software, are owned by SurveyDex or its licensors.<br />
                                4.2. Users retain ownership of the content they submit to the Service. By using SurveyDex, Users grant SurveyDex a non-exclusive, worldwide, royalty-free license to use, modify, reproduce, and distribute the content for the purpose of providing the Service.
                            </p>
                            <strong>5. Data Privacy</strong>
                            <p>5.1. SurveyDex is committed to protecting the privacy and security of Users' personal information. Our Privacy Policy outlines how we collect, use, and safeguard data. By using the Service, you consent to our collection and use of your information as described in the Privacy Policy.</p>
                            <strong>6. Termination</strong>
                            <p>6.1. SurveyDex reserves the right to terminate or suspend any User's access to the Service, with or without notice, for any reason, including but not limited to violation of these Terms.</p>
                            <strong>7. Disclaimer of Warranties</strong>
                            <p>7.1. The Service is provided on an "as-is" and "as available" basis. SurveyDex makes no warranties, express or implied, regarding the accuracy, reliability, or suitability of the Service for any purpose.</p>
                            <strong>8. Limitation of Liability</strong>
                            <p>8.1. To the extent permitted by law, SurveyDex shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with the use of the Service or these Terms.</p>
                            <strong>9. Changes to Terms and Service</strong>
                            <p>9.1. SurveyDex reserves the right to modify or update these Terms at any time. The most current version of the Terms will be posted on our website. Continued use of the Service after any changes constitutes acceptance of the updated Terms.</p>

                            <br />
                            <p>
                                By using SurveyDex, you acknowledge that you have read and understood these Terms and agree to be bound by them. If you do not agree to these Terms, you must refrain from using the Service.
                                <br /><br />
                                Last updated: 1st June 2023
                            </p>
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default TermsConditions;
