import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";

import { toast } from "react-toastify";
import { CHAIN_ID, NODE_ADDRESS, NODE_URL } from "../../config/bsc_node";
import { USDT_ABI, USDT_ADDRESS } from "../../config/bsc_usdt";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useParams } from "react-router-dom";
var _ = require('lodash');

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                56: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}
const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});

class LuxuryCarSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: props.params.survey_id,
            title: props.params.title,
            account: "",
            is_approve_usdt: 0,
            is_connected:false,
            auth_token: undefined,
            is_complete: false,

            options: {
                lc_gender: [
                    { value: 1, label: "Female" },
                    { value: 2, label: "Male" },
                ],
                lc_model_year: [
                    { value: 1, label: "Jaguar XF 2013" },
                    { value: 2, label: "Jaguar XJ 2013" },
                    { value: 3, label: "Jaguar F-Type 2013" },
                    { value: 4, label: "Jaguar XK 2013" },
                    { value: 5, label: "Audi A6 2013" },
                    { value: 6, label: "Audi A8 2013" },
                    { value: 7, label: "BMW 5-Series 2013" },
                    { value: 8, label: "BMW 7-Series 2013" },
                    { value: 9, label: "Mercedes E-Class 2013" },
                    { value: 10, label: "Mercedes S-Class 2013" },
                    { value: 11, label: "Lexus GS 2013" },
                    { value: 12, label: "Lexus LS 2013" },
                    { value: 13, label: "Other 2013" },
                    { value: 14, label: "Jaguar XF 2014" },
                    { value: 15, label: "Jaguar XJ 2014" },
                    { value: 16, label: "Jaguar F-Type 2014" },
                    { value: 17, label: "Jaguar XK 2014" },
                    { value: 18, label: "Audi A6 2014" },
                    { value: 19, label: "Audi A8 2014" },
                    { value: 20, label: "BMW 5-Series 2014" },
                    { value: 21, label: "BMW 7-Series 2014" },
                    { value: 22, label: "Mercedes E-Class 2014" },
                    { value: 23, label: "Mercedes S-Class 2014" },
                    { value: 24, label: "Lexus GS 2014" },
                    { value: 25, label: "Lexus LS 2014" },
                    { value: 26, label: "Other 2014" },
                ],
                lc_contributes: [
                    { value: 1, label: "Me alone" },
                    { value: 2, label: "With contributing partner" },
                ],
                lc_annual_household_income: [
                    { value: 1, label: "Less than $80,000" },
                    { value: 2, label: "$80,000-$90,000" },
                    { value: 3, label: "$90,000-$125,000" },
                    { value: 4, label: "$125,000-$150,000" },
                    { value: 5, label: "$150,000 and more" },
                ],
                lc_education: [
                    { value: 1, label: "Some high school or less " },
                    { value: 2, label: "Graduated high school or equivalent " },
                    { value: 3, label: "Trade/vocational school " },
                    { value: 4, label: "Some college " },
                    { value: 5, label: "Bachelor's degree " },
                    { value: 6, label: "Master's degree " },
                    { value: 7, label: "PhD " },
                ],
                lc_ethnic_background: [
                    { value: 1, label: "Caucasian " },
                    { value: 2, label: "Hispanic " },
                    { value: 3, label: "African American " },
                    { value: 4, label: "Asian or Pacific Islander " },
                    { value: 'other', label: "Others" },
                ],
                lc_areas: [
                    { value: 1, label: "Los Angeles" },
                    { value: 2, label: "New York" },
                    { value: 3, label: "Dallas" },
                    { value: 'other', label: "Others" },
                ],
            },
            errors: []
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }

        let provider;
        if (web3Modal.cachedProvider) {
            provider = await web3Modal.connect()
        }

        if (provider) {
            this.provider = provider;
            const web3 = new Web3(provider);
            this.web3 = web3;
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                //Check For USDT Approve
                const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                    this.setState({ is_approve_usdt: 1, step: 2 })
                }
                const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${Number(97).toString(16)}`, }],
                });
                setTimeout(function () {

                }, 3000)
                return 0;
            }
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3 = web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1, step: 2 })
                    }
                    const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                    this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
    }

    /**
     * Save Survey
     */
    async saveData() {
        let that = this;
        that.setState({ isLoading: true });

        var errors_ = [];
        if (!that.state.lc_oi_title || that.state.lc_oi_title == null || that.state.lc_oi_title == '') {
            errors_['lc_oi_title'] = "This field is required!"
        }
        if (!that.state.lc_age || that.state.lc_age == null || that.state.lc_age == '') {
            errors_['lc_age'] = "This field is required!"
        }
        if (!that.state.lc_gender || that.state.lc_gender == null || that.state.lc_gender == '') {
            errors_['lc_gender'] = "This field is required!"
        }
        if (!that.state.lc_model_year || that.state.lc_model_year == null || that.state.lc_model_year == '') {
            errors_['lc_model_year'] = "This field is required!"
        }
        if (!that.state.lc_contributes || that.state.lc_contributes == null || that.state.lc_contributes == '') {
            errors_['lc_contributes'] = "This field is required!"
        }
        if (!that.state.lc_annual_household_income || that.state.lc_annual_household_income == null || that.state.lc_annual_household_income == '') {
            errors_['lc_annual_household_income'] = "This field is required!"
        }
        if (!that.state.lc_education || that.state.lc_education == null || that.state.lc_education == '') {
            errors_['lc_education'] = "This field is required!"
        }
        if (!that.state.lc_ethnic_background || that.state.lc_ethnic_background == null || that.state.lc_ethnic_background == '') {
            errors_['lc_ethnic_background'] = "This field is required!"
        }
        if (!that.state.lc_areas || that.state.lc_areas == null || that.state.lc_areas == '') {
            errors_['lc_areas'] = "This field is required!"
        }

        if (errors_) {
            that.setState({ errors: errors_ });
        } else {
            that.setState({ errors: [] });
        }

        var json = {
            lc_oi_title: that.state.lc_oi_title,
            lc_age: that.state.lc_age,
            lc_gender: that.state.lc_gender,
            lc_model_year: that.state.lc_model_year,
            lc_contributes: that.state.lc_contributes,
            lc_annual_household_income: that.state.lc_annual_household_income,
            lc_education: that.state.lc_education,
            lc_ethnic_background: that.state.lc_ethnic_background,
            lc_areas: that.state.lc_areas,
        }
        console.log(that.state.errors, json);

        this.completeSurvey(this.state.survey_id)
    }

    /**
     * Collect
     */
    async completeSurvey(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.survey_store,
            data: {
                survey: id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({ is_complete: true });
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /** 
     * Approve USDT 
     */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ isLoading: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        that.setState({ isLoading: 0, is_approve_usdt: 1, step: 2 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        toast.error(error.message);
                        that.setState({ isLoading: 0, step: 2 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing",
                    success: "Completed",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>{this.state.title}</h1>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.is_complete == false ?
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <form>
                                                    <div className="row">
                                                        <div className="form-group mt-3">
                                                            <label className="question mb-2">1. What occupation & industry are you in?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                value={this.state.lc_oi_title ?? ''}
                                                                onChange={(e) => this.setState({ lc_oi_title: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["lc_oi_title"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="question mb-2">2. What is your age?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                value={this.state.lc_age ?? ''}
                                                                onChange={(e) => this.setState({ lc_age: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["lc_age"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">3. What is your gender?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.lc_gender.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="lc_gender" id={"lc_gender_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ lc_gender: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"lc_gender_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["lc_gender"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">4. Which of the following best describes the make, model and year of the car you own?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.lc_model_year.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="lc_model_year" id={"lc_model_year_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ lc_model_year: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"lc_model_year_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["lc_model_year"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">5. Is your annual household income provided solely by you, or do you have a partner who also contributes?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.lc_contributes.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="lc_contributes" id={"lc_contributes_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ lc_contributes: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"lc_contributes_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["lc_contributes"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">6. Which of the following best describes your annual household income?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.lc_annual_household_income.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="lc_annual_household_income" id={"lc_annual_household_income_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ lc_annual_household_income: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"lc_annual_household_income_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["lc_annual_household_income"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">7. What is the highest level of education that you have completed?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.lc_education.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="lc_education" id={"lc_education_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ lc_education: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"lc_education_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["lc_education"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">8. Which of the following best describes your ethnic background?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.lc_ethnic_background.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="lc_ethnic_background" id={"lc_ethnic_background_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ lc_ethnic_background: (e.target.value == "other") ? '' : e.target.value, lc_ethnic_background_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"lc_ethnic_background_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.lc_ethnic_background_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.lc_ethnic_background ?? ''}
                                                                        onChange={(e) => this.setState({ lc_ethnic_background: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["lc_ethnic_background"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">9. Which of the following areas describes where you live?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.lc_areas.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="lc_areas" id={"lc_areas_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ lc_areas: (e.target.value == "other") ? '' : e.target.value, lc_areas_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"lc_areas_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.lc_areas_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.lc_areas ?? ''}
                                                                        onChange={(e) => this.setState({ lc_areas: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["lc_areas"]}</span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mt-3">
                                                        {
                                                            this.state.isLoading ?
                                                                <button type="button" className="btn btn-primary me-2">
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    Loading...
                                                                </button>
                                                                :
                                                                this.state.is_approve_usdt ?
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.saveData()}>Complete Now</button>
                                                                    :
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.approveUSDT()}>Enrol Now</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <div className="col text-center">
                                                    <h1>{this.state.title}</h1>
                                                    <img src={api.SiteURL + "assets/images/success.svg"} style={{ height: 100 }} />
                                                    <p>Thank you</p>
                                                    <small>Your Response is recorded</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </>
                <Footer />
            </>
        );
    }
}
function withRouter(Child) {
    return (props) => {
        const params = useParams();
        return <Child {...props} params={params} />;
    }
}
export default withRouter(LuxuryCarSurvey);
