import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CHAIN_ID, NODE_ADDRESS, NODE_URL } from "../../config/bsc_node";
import { USDT_ABI, USDT_ADDRESS } from "../../config/bsc_usdt";
import Web3 from "web3";
import Web3Modal from "web3modal";

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                56: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}
const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});

class Reward extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth_token: undefined,
            is_load: true,
            data: [],
            links: [],

            account: "",
            is_approve_usdt: 0,
            is_connected:false,
        }
    }

    async componentDidMount() {
        this.getReports(api.survey_list);
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
            setTimeout(() => {
                
            });
        }

        let provider=null;
        /*if (web3Modal.cachedProvider) {
            provider = await web3Modal.connect()
        }
        else {
            provider = await web3Modal.connect();
        }*/

        if (provider) {
            this.provider = provider;
            const web3 = new Web3(provider);
            this.web3 = web3;
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                //Check For USDT Approve
                const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                    this.setState({ is_approve_usdt: 1, step: 2 })
                }
                const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${Number(97).toString(16)}`, }],
                });
                setTimeout(function () {

                }, 3000)
                return 0;
            }
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3 = web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1, step: 2 })
                    }
                    const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                    this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
    }

    /**
     * Get Report
     */
    async getReports(URL) {
        let that = this;
        await axios({
            method: 'get',
            url: URL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                if (ress.data.data) {
                    that.setState({
                        data: ress.data.data.data,
                        links: ress.data.data.links,
                    })

                } else {
                    that.setState({
                        data: [],
                        links: [],
                    })
                }
            }
            else {
            }

            setTimeout(() => {
                that.setState({ is_load: false });
            });
        }).catch(function (e) {
            setTimeout(() => {
                that.setState({ is_load: false });
            });
        });
    }

    /**
     * Collect
     */
    async completeSurvey(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.survey_store,
            data: {
                survey: id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /** 
    * Approve USDT 
    */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ isLoading: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        //console.log("Receipt",receipt);
                        that.setState({ isLoading: 0, is_approve_usdt: 1, step: 2 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        toast.error(error.message);
                        that.setState({ isLoading: 0, step: 2 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing",
                    success: "Completed",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }

    getURL(data) {
        if (data.category == 1) {
            return "/survey-luxury-bag/" + data.en_title + "/" + data.id;
        }
        else if (data.category == 2) {
            return "/survey-fashion/" + data.en_title + "/" + data.id;
        }
        else if (data.category == 3) {
            return "/survey-luxury-watch/" + data.en_title + "/" + data.id;
        }
        else if (data.category == 4) {
            return "/survey-skincare/" + data.en_title + "/" + data.id;
        }
        else if (data.category == 5) {
            return "/survey-electric-car/" + data.en_title + "/" + data.id;
        }
        else if (data.category == 6) {
            return "/survey-luxury-car/" + data.en_title + "/" + data.id;
        }
        else if (data.category == 7) {
            return "/survey-sportswear/" + data.en_title + "/" + data.id;
        }
    }
    render() {
        let self = this;
        return (
            <>
                <Header connect={false}/>
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>Rewards</h1>
                                    {/* <p>Be rewarded while you contribute to meaningful research!</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="friend">
                            <div className="row">
                                <div className="col-3">
                                    <figure>
                                        <img src="assets/images/wjs_03.png" alt="" />
                                    </figure>
                                    <h4>Refer friend</h4>
                                </div>
                                <div className="col-6">
                                    <figure>
                                        <img src="assets/images/reward.png" alt="" />
                                    </figure>
                                    <h3>Earn Dex Rewards</h3>
                                </div>
                                <div className="col-3">
                                    <figure>
                                        <img src="assets/images/wjs_03.png" alt="" />
                                    </figure>
                                    <h4>Refer friend</h4>
                                </div>
                                <div className="col-12 mt-5 mb-4">
                                    <h2>Can’t wait to start?</h2>
                                    <p>Login now to refer your friends!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="result">
                        <div className="container">
                            {/*<div className="row">
                                <div className="col-12 mb-4">
                                    <ul className="nav nav-pills mx-auto">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#">All</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Shop</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Play</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Travel</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Eat</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>*/}
                            <div className="row gy-4 gx-3">
                                {
                                    !this.state.is_load ?
                                        <>
                                            <div className="container">
                                                <div className="bansub">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h1>Survey </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                this.state.data.length > 0 ?
                                                    this.state.data.map((data, key) =>
                                                        <div className="col" key={"A" + 100 + key}>
                                                            <div className="box">
                                                                <figure>
                                                                    <img src={api.SurveyImage + data.image} alt="" />
                                                                </figure>
                                                                <h3>{data.en_title}</h3>
                                                                <p>{data.en_content}</p>
                                                                <h4>100 Dex Point</h4>
                                                                {
                                                                    self.state.isLoading ?
                                                                        <button type="button" className="btn btn-primary py-1 px-4">
                                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                            Loading...
                                                                        </button>
                                                                        :
                                                                        <a type="button" className="btn btn-primary py-1 px-4" href={self.getURL(data)}>Details</a>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <tr>
                                                        <td colSpan={3}>No Data Avaialble</td>
                                                    </tr>
                                            }

                                            <div className="mt-2 mt-2 d-flex justify-content-center">
                                                <ul className="pagination">
                                                    {
                                                        this.state.links.map((item, key) =>
                                                            <li key={100 + key} className="page-item">
                                                                <Link
                                                                    className={(item.active) ? "page-link active" : "page-link"}
                                                                    onClick={() => (item.url) ? this.getReports(item.url) : null}
                                                                >
                                                                    {item.label}
                                                                </Link>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default Reward;
