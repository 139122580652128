import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";

import { toast } from "react-toastify";
import { CHAIN_ID, NODE_ADDRESS, NODE_URL } from "../../config/bsc_node";
import { USDT_ABI, USDT_ADDRESS } from "../../config/bsc_usdt";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useParams } from "react-router-dom";
var _ = require('lodash');

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                56: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}
const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});
class ElectricCarSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: props.params.survey_id,
            title: props.params.title,
            account: "",
            is_approve_usdt: 0,
            is_connected:false,
            auth_token: undefined,
            is_complete: false,

            options: {
                ec_owned: [
                    { value: 1, label: "Yes, a hybrid electric/petrol car" },
                    { value: 2, label: "Yes, a fully electric car" },
                    { value: 3, label: "No, I have never owned an electric car" },
                ],
                ec_driven: [
                    { value: 1, label: "Yes, a hybrid electric/ petrol car" },
                    { value: 2, label: "Yes a fully electric car" },
                    { value: 3, label: "No, I've never driven an electric car" },
                ],
                ec_benefits: [
                    { value: 1, label: "Electric vehicles are much quieter than other vehicles" },
                    { value: 2, label: "Electric vehicles have excellent acceleration" },
                    { value: 3, label: "Electric vehicles are environmentally friendly because they have zero emissions" },
                    { value: 4, label: "The cost to charge an electric vehicle is much less than the fuel costs for a petrol or diesel vehicle" },
                    { value: 5, label: "Electric vehicles cost about the same to buy as petrol or diesel vehicles" },
                    { value: 6, label: "Electric vehicle technology has improved and they now have a much better range" },
                ],
                ec_buy_next_two_year: [
                    { value: 1, label: "Will definitely buy one" },
                    { value: 2, label: "Am likely to buy one" },
                    { value: 3, label: "Am considering buying one but need convincing" },
                    { value: 4, label: "Unlikely to buy one" },
                    { value: 5, label: "Definitely won't buy one" },
                    { value: 6, label: "Don't know" },
                ],
                ec_range: [
                    { value: 1, label: "Range from 1 to 10 & N/A" },
                    { value: 2, label: "1 is not at all important" },
                    { value: 3, label: "5 is quite important" },
                    { value: 4, label: "10 is most important" },
                    { value: 5, label: "The fuel economy/ cost to charge it" },
                    { value: 6, label: "The initial purchase cost" },
                    { value: 7, label: "How well it drives/ performance" },
                    { value: 8, label: "The environmental benefits" },
                    { value: 9, label: "How good the car looks" },
                    { value: 10, label: "The ability to buy one second-hand" },
                    { value: 11, label: "How many charging stations are available/ how easy it is to recharge when you are out and about" },
                    { value: 12, label: "The maintenance costs such as servicing" },
                    { value: 'other', label: "Are there any other factors that are important to you (please specify)" },
                ],
                ec_distances: [
                    { value: 1, label: "Short distances near to where I live" },
                    { value: 2, label: "Medium distances (up to 100km trips)" },
                    { value: 3, label: "Longer distances (over 100km trips)" },
                ],
                ec_gender: [
                    { value: 1, label: "Male" },
                    { value: 2, label: "Female" },
                ],
                ec_age: [
                    { value: 1, label: "Under 25 years" },
                    { value: 2, label: "25-39 years" },
                    { value: 3, label: "40-59 years" },
                    { value: 4, label: "60 years or over" },
                ],
                ec_vehicles_own: [
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3 or more" },
                ],
            },
            errors: []
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }

        let provider;
        if (web3Modal.cachedProvider) {
            provider = await web3Modal.connect()
        }
     
        if (provider) {
            this.provider = provider;
            const web3 = new Web3(provider);
            this.web3 = web3;
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                //Check For USDT Approve
                const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                    this.setState({ is_approve_usdt: 1, step: 2 })
                }
                const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${Number(97).toString(16)}`, }],
                });
                setTimeout(function () {

                }, 3000)
                return 0;
            }
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3 = web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1, step: 2 })
                    }
                    const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                    this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
    }

    /**
     * Save Survey
     */
    async saveData() {
        let that = this;
        that.setState({ isLoading: true });

        var errors_ = [];
        if (!that.state.ec_owned || that.state.ec_owned == null || that.state.ec_owned == '') {
            errors_['ec_owned'] = "This field is required!"
        }
        if (!that.state.ec_driven || that.state.ec_driven == null || that.state.ec_driven == '') {
            errors_['ec_driven'] = "This field is required!"
        }
        if (!that.state.ec_benefits_advantages || that.state.ec_benefits_advantages == null || that.state.ec_benefits_advantages == '') {
            errors_['ec_benefits_advantages'] = "This field is required!"
        }
        if (!that.state.ec_benefits_disadvantages || that.state.ec_benefits_disadvantages == null || that.state.ec_benefits_disadvantages == '') {
            errors_['ec_benefits_disadvantages'] = "This field is required!"
        }
        if (!that.state.ec_benefits || that.state.ec_benefits == null || that.state.ec_benefits == '') {
            errors_['ec_benefits'] = "This field is required!"
        }
        if (!that.state.ec_buy_next_two_year || that.state.ec_buy_next_two_year == null || that.state.ec_buy_next_two_year == '') {
            errors_['ec_buy_next_two_year'] = "This field is required!"
        }
        if (!that.state.ec_range || that.state.ec_range == null || that.state.ec_range == '') {
            errors_['ec_range'] = "This field is required!"
        }
        if (!that.state.ec_charge || that.state.ec_charge == null || that.state.ec_charge == '') {
            errors_['ec_charge'] = "This field is required!"
        }
        if (!that.state.ec_charge_battery || that.state.ec_charge_battery == null || that.state.ec_charge_battery == '') {
            errors_['ec_charge_battery'] = "This field is required!"
        }
        if (!that.state.ec_distances || that.state.ec_distances == null || that.state.ec_distances == '') {
            errors_['ec_distances'] = "This field is required!"
        }
        if (!that.state.ec_gender || that.state.ec_gender == null || that.state.ec_gender == '') {
            errors_['ec_gender'] = "This field is required!"
        }
        if (!that.state.ec_age || that.state.ec_age == null || that.state.ec_age == '') {
            errors_['ec_age'] = "This field is required!"
        }
        if (!that.state.ec_vehicles_own || that.state.ec_vehicles_own == null || that.state.ec_vehicles_own == '') {
            errors_['ec_vehicles_own'] = "This field is required!"
        }

        if (errors_) {
            that.setState({ errors: errors_ });
        } else {
            that.setState({ errors: [] });
        }

        var json = {
            ec_owned: that.state.ec_owned,
            ec_driven: that.state.ec_driven,
            ec_benefits_advantages: that.state.ec_benefits_advantages,
            ec_benefits_disadvantages: that.state.ec_benefits_disadvantages,
            ec_benefits: that.state.ec_benefits,
            ec_buy_next_two_year: that.state.ec_buy_next_two_year,
            ec_range: that.state.ec_range,
            ec_charge: that.state.ec_charge,
            ec_charge_battery: that.state.ec_charge_battery,
            ec_distances: that.state.ec_distances,
            ec_gender: that.state.ec_gender,
            ec_age: that.state.ec_age,
            ec_vehicles_own: that.state.ec_vehicles_own,
        }
        console.log(that.state.errors, json);

        this.completeSurvey(this.state.survey_id)
    }

    /**
     * Collect
     */
    async completeSurvey(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.survey_store,
            data: {
                survey: id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({ is_complete: true });
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /** 
     * Approve USDT 
     */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ isLoading: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        that.setState({ isLoading: 0, is_approve_usdt: 1, step: 2 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        toast.error(error.message);
                        that.setState({ isLoading: 0, step: 2 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing",
                    success: "Completed",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }

    handleClick1 = (e) => {
        const { value, checked } = e.target;
        var scp_d = this.state.scp_use_in_week;
        if (checked == false) { scp_d = _.filter(scp_d, function (o) { return o != value; }); } else { scp_d.push(value) }
        this.setState({ scp_use_in_week: scp_d })
    };

    handleClick2 = (e) => {
        const { value, checked } = e.target;
        var scp_d = this.state.scp_use_to_learn;
        if (checked == false) { scp_d = _.filter(scp_d, function (o) { return o != value; }); } else { scp_d.push(value) }
        this.setState({ scp_use_to_learn: scp_d })
    };

    handleClick3 = (e) => {
        const { value, checked } = e.target;
        var scp_d = this.state.scp_factors_matter;
        if (checked == false) { scp_d = _.filter(scp_d, function (o) { return o != value; }); } else { scp_d.push(value) }
        this.setState({ scp_factors_matter: scp_d })
    };

    handleClick4 = (e) => {
        const { value, checked } = e.target;
        var scp_d = this.state.scp_ingredients;
        if (checked == false) { scp_d = _.filter(scp_d, function (o) { return o != value; }); } else { scp_d.push(value) }
        this.setState({ scp_ingredients: scp_d })
    };

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>{this.state.title}</h1>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.is_complete == false ?
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <form>
                                                    <div className="row">

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">1. Have you ever personally owned an electric car?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_owned.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_owned" id={"ec_owned_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_owned: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_owned_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["ec_owned"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">2. And have you ever driven an electric car?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_driven.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_driven" id={"ec_driven_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_driven: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_driven_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["ec_driven"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="question mb-2">3. What do you consider the main benefits or advantages of electric vehicles?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                placeholder="What is your name"
                                                                value={this.state.ec_benefits_advantages ?? ''}
                                                                onChange={(e) => this.setState({ ec_benefits_advantages: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["ec_benefits_advantages"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="question mb-2">4. And what do you consider the main disadvantages of electric vehicles?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                placeholder="What is your name"
                                                                value={this.state.ec_benefits_disadvantages ?? ''}
                                                                onChange={(e) => this.setState({ ec_benefits_disadvantages: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["ec_benefits_disadvantages"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">5. Below are some statements people have made about the benefits of electric vehicles. For each statement, please indicate whether your personally agree or disagree with that statement.
                                                                (Strongly Agree, Agree, Neutral, Disagree, Strongly Disagree, Don't Know)
                                                            </label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_benefits.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_benefits" id={"ec_benefits_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_benefits: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_benefits_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["ec_benefits"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">6. How likely are you to consider buying an electric vehicle in the next 2 years?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_buy_next_two_year.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_buy_next_two_year" id={"ec_buy_next_two_year_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_buy_next_two_year: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_buy_next_two_year_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["ec_buy_next_two_year"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">7.  Below are a list of factors other people have told us are important when considering whether to buy an electric vehicle. For each factor please tell us how important it would be to you if you were considering purchasing an electric vehicle</label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_range.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_range" id={"ec_range_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_range: (e.target.value == "other") ? '' : e.target.value, ec_range_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_range_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.ec_range_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.ec_range ?? ''}
                                                                        onChange={(e) => this.setState({ ec_range: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["ec_range"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="question mb-2">8. How quickly (hours) would it need to take to fully charge an electric vehicle for you to consider buying one?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                placeholder="What is your name"
                                                                value={this.state.ec_charge ?? ''}
                                                                onChange={(e) => this.setState({ ec_charge: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["ec_charge"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="question mb-2">9.  How far (kilometres) would you expect to be able to drive an electric vehicle on a fully charged battery for you to consider buying one?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                placeholder="What is your name"
                                                                value={this.state.ec_charge_battery ?? ''}
                                                                onChange={(e) => this.setState({ ec_charge_battery: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["ec_charge_battery"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">10. Thinking about how far you drive, how often on average do you make each of the following types of trips?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_distances.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_distances" id={"ec_distances_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_distances: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_distances_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["ec_distances"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">11. What is your gender?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_gender.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_gender" id={"ec_gender_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_gender: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_gender_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["ec_gender"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">12. Which of the following age groups are you in?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_age.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_age" id={"ec_age_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_age: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_age_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["ec_age"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">13. How many vehicles do you / your household currently own?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.ec_vehicles_own.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="ec_vehicles_own" id={"ec_vehicles_own_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ ec_vehicles_own: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"ec_vehicles_own_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["ec_vehicles_own"]}</span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mt-3">
                                                        {
                                                            this.state.isLoading ?
                                                                <button type="button" className="btn btn-primary me-2">
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    Loading...
                                                                </button>
                                                                :
                                                                this.state.is_approve_usdt ?
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.saveData()}>Complete Now</button>
                                                                    :
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.approveUSDT()}>Enrol Now</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <div className="col text-center">
                                                    <h1>{this.state.title}</h1>
                                                    <img src={api.SiteURL + "assets/images/success.svg"} style={{ height: 100 }} />
                                                    <p>Thank you</p>
                                                    <small>Your Response is recorded</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </>
                <Footer />
            </>
        );
    }
}
function withRouter(Child) {
    return (props) => {
        const params = useParams();
        return <Child {...props} params={params} />;
    }
}
export default withRouter(ElectricCarSurvey);
