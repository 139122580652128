import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <Header connect={false}/>
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>FAQS</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="dashboard">
                        <div className="container">
                            <div>
                                <div>
                                    <strong>1.What kind of rewards do you offer for completing surveys?</strong>
                                    <p>Our survey company offers a variety of rewards, which may include cash payments, gift cards, vouchers, or entries into sweepstakes and prize draws.</p>
                                </div>
                                <div>
                                    <strong>2.How do I earn rewards by participating in surveys?</strong>
                                    <p>You can earn rewards by completing surveys available on our platform. Each survey you complete will earn you points or credits that can be redeemed for the rewards of your choice.</p>
                                </div>
                                <div>
                                    <strong>3.Are there any eligibility criteria to receive rewards?</strong>
                                    <p>To earn rewards, you must be a registered participant and meet specific eligibility criteria for each survey, such as age, location, or other demographic factors.</p>
                                </div>
                                <div>
                                    <strong>4.When will I receive my rewards after completing a survey?</strong>
                                    <p>The timing of reward distribution varies depending on the survey and its requirements. In general, rewards are processed once the survey responses are validated.</p>
                                </div>
                                <div>
                                    <strong>5.Can I choose the type of rewards I want to receive?</strong>
                                    <p>Yes, you can often choose from a selection of rewards available, including cash, gift cards, or other options, depending on the surveys you complete.</p>
                                </div>
                                <div>
                                    <strong>6.Is there a minimum threshold for redeeming rewards?</strong>
                                    <p>Some surveys may have a minimum points threshold that must be reached before you can redeem rewards. Once you reach the threshold, you can claim your rewards.</p>
                                </div>
                                <div>
                                    <strong>7.Are there any restrictions on how many surveys I can complete to earn rewards?</strong>
                                    <p>You can participate in as many surveys as you qualify for, but each survey may have a limited number of available spots, so it's essential to act quickly when invited.</p>
                                </div>
                                <div>
                                    <strong>8.Is my personal information safe when earning rewards through surveys?</strong>
                                    <p>Yes, we take data privacy seriously and ensure that your personal information is protected and used only for research purposes in accordance with our privacy policy.</p>
                                </div>
                                <div>
                                    <strong>9.Do I need to pay any fees to participate in surveys and earn rewards?</strong>
                                    <p>No, participation in surveys and earning rewards is completely free. You should never be asked to pay any fees to participate.</p>
                                </div>
                                <div>
                                    <strong>10.How can I track my earned rewards and see my redemption options?</strong>
                                    <p>You can usually track your earned rewards and view redemption options within your survey account dashboard. This will show your accumulated points and available rewards.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default Faq;
