import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Aboutus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <Header connect={false}/>
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>About Us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="dashboard">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                    <img src="assets/images/about_1.png" alt="" />
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                    <p>
                                        At SurveyDex, we are passionate about leveraging the power of Web3 technology to revolutionize the survey industry. We believe that surveys can be more than just a means of data collection; they can be a catalyst for engagement, empowerment, and meaningful change.
                                    </p>
                                    <p>
                                        As a web3 survey platform, we harness the decentralized and transparent nature of blockchain technology to provide a secure, trustless, and inclusive environment for survey participants. By integrating blockchain, we aim to address some of the key challenges in traditional survey methodologies, such as data privacy, reliability, and participant incentives.
                                    </p>
                                    <p>
                                        Our platform is built on the principles of openness, decentralization, and user-centricity. We strive to empower individuals to share their opinions and contribute to decision-making processes that impact their lives. We believe that everyone's voice matters and that by capturing diverse perspectives, we can uncover valuable insights that drive innovation, inform policies, and shape the future.
                                    </p>

                                    <h3 className="mt-5">Our Mission</h3>

                                    <div>
                                        <p>At SurveyDex, our mission is to provide our clients with the most comprehensive and insightful survey data possible. We understand that in today's fast-paced business world, making informed decisions quickly is crucial. That's why we offer a range of services designed to help our clients gather the data they need to make those decisions with confidence.</p>
                                        <p>We believe that every client is unique, and that's why we take the time to understand their specific needs and goals. Whether it's market research, customer feedback, or employee satisfaction surveys, we work closely with our clients to design surveys that are tailored to their needs. Our team of experts then uses the latest tools and techniques to collect and analyze the data, providing our clients with actionable insights that they can use to drive their businesses forward.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default Aboutus;
