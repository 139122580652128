import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Slider from "react-slick";
import api from "../../config/api";
import axios from "axios";

class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth_token: undefined,
            is_load: true,
            data: [],
            links: [],
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
            setTimeout(() => {
                this.getReports(api.artical);
            });
        }
    }

    /**
     * Get Report
     */
    async getReports(URL) {
        let that = this;
        await axios({
            method: 'get',
            url: URL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                if (ress.data.data) {
                    that.setState({
                        data: ress.data.data.data,
                        links: ress.data.data.links,
                    })

                } else {
                    that.setState({
                        data: [],
                        links: [],
                    })
                }
            }
            else {
            }

            setTimeout(() => {
                that.setState({ is_load: false });
            });
        }).catch(function (e) {
            setTimeout(() => {
                that.setState({ is_load: false });
            });
        });
    }

    render() {
        const settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2.11,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 375,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                        dots: false,
                        arrows: false,
                    }
                }
            ]

        };
        return (
            <>
                <Header connect={false}/>
                <>
                    <section className="article">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h2>Article</h2>
                                    <h3>Paid Online Surveys</h3>
                                    <p>Here are 3 reasons why participating in paid surveys is rewarding, especially here at SurveyDex.com:</p>
                                    <p>
                                        <strong>
                                            1. Sharing Your Opinion<br />
                                            2. Flexibility and Convenience<br />
                                            3. Earn Extra Income in the form of USDT
                                        </strong>
                                    </p>
                                    <p>Simply do surveys, get rewarded with SurveyPoints, and earn money. Let’s aim to inspire a better future for you – one survey at a time.</p>
                                    <br />
                                </div>
                                <div className="col-8 mb-4">
                                    <form className="search">
                                        <input className="form-control rounded-pill" type="text" placeholder="Search article keyword" />
                                        <button className="btn btn-link position-absolute top-0 end-0 me-1 py-0" type="submit"><img src="assets/images/icn_search_01.svg" width="26" alt="" /></button>
                                    </form>
                                </div>
                                <div className="col-4 mb-4">
                                    <div className="dropdown float-end">
                                        <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Sort by
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">A - Z</a></li>
                                            <li><a className="dropdown-item" href="#">Z - A</a></li>
                                        </ul>
                                    </div>
                                </div>

                                {
                                    !this.state.is_load ?
                                        <>

                                            {
                                                this.state.data.length > 0 ?
                                                    this.state.data.map((data, key) =>
                                                        <div className="col-md-6" key={"A" + 100 + key}>
                                                            <div className="panel">
                                                                <div className="row">
                                                                    <div className="col-5">
                                                                        <picture><img src={api.NewsImage + data.image} alt="" /></picture>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <h3>{data.en_title}</h3>
                                                                        <p>{data.en_content}</p>
                                                                        <div className="tag mb-2">Tags: -</div>
                                                                        <div className="row gx-2 align-items-center">
                                                                            <div className="col-6">
                                                                                <a href={data.url} className="btn btn-outline-primary rounded-pill" target="_blank">Read more</a>
                                                                            </div>
                                                                            <div className="col-6 member">
                                                                                <img src="assets/images/icn_eye.svg" alt="" /> Member only
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <div>
                                                        <div className="text-center">No Data Avaialble</div>
                                                    </div>
                                            }

                                            <div className="mt-2 mt-2 d-flex justify-content-center">
                                                <ul className="pagination">
                                                    {
                                                        this.state.links.map((item, key) =>
                                                            <li key={100 + key} className="page-item">
                                                                <Link
                                                                    className={(item.active) ? "page-link active" : "page-link"}
                                                                    onClick={() => (item.url) ? this.getReports(item.url) : null}
                                                                >
                                                                    {item.label}
                                                                </Link>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default Article;
