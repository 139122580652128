import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";

import { toast } from "react-toastify";
import { CHAIN_ID, NODE_ADDRESS, NODE_URL } from "../../config/bsc_node";
import { USDT_ABI, USDT_ADDRESS } from "../../config/bsc_usdt";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useParams } from "react-router-dom";
var _ = require('lodash');

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                56: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}
const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});
class SportswearSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: props.params.survey_id,
            title: props.params.title,
            account: "",
            is_approve_usdt: 0,
            is_connected:false,
            auth_token: undefined,
            is_complete: false,

            options: {
                gender: [
                    { value: 1, label: "Female" },
                    { value: 2, label: "Male" },
                ],
                age: [
                    { value: 1, label: "0-17" },
                    { value: 2, label: "18-24" },
                    { value: 3, label: "25-34" },
                    { value: 4, label: "35-44" },
                    { value: 5, label: "45-54" },
                    { value: 6, label: "55-64" },
                    { value: 7, label: "65 & above" },
                ],
                martial_status: [
                    { value: 1, label: "Single" },
                    { value: 2, label: "Married" },
                    { value: 3, label: "Divorced" },
                    { value: 4, label: "Widowed" },
                ],
                annual_income_range: [
                    { value: 1, label: "9,999 or less" },
                    { value: 2, label: "10,000 to 24,999" },
                    { value: 3, label: "25,000 to 49,999" },
                    { value: 4, label: "50,000 to 74,999" },
                    { value: 5, label: "75,000 to 99,999" },
                    { value: 6, label: "100,000 & above" },
                ],
                employment_status: [
                    { value: 1, label: "Employed full time" },
                    { value: 2, label: "Employed part time" },
                    { value: 3, label: "Self-employed" },
                    { value: 4, label: "Unemployed" },
                    { value: 5, label: "Not looking for a job" },
                    { value: 6, label: "Homemaker" },
                    { value: 7, label: "Student" },
                ],
                education_level: [
                    { value: 1, label: "Less than high school" },
                    { value: 2, label: "High school" },
                    { value: 3, label: "Some college / University" },
                    { value: 4, label: "College diploma / Certificate" },
                    { value: 5, label: "Undergraduate degree" },
                    { value: 6, label: "Masters / Graduate Degree" },
                    { value: 7, label: "Doctorate" },
                    { value: "other", label: "Others" },
                ],
                exercise: [
                    { value: 1, label: "Range from 1 to 10" },
                    { value: 2, label: "1 is not so often" },
                    { value: 3, label: "10 is Extremely often" },
                ],
                sportswear_products: [
                    { value: 1, label: "Range from 1 to 10" },
                    { value: 2, label: "1 is not so often" },
                    { value: 3, label: "10 is Extremely often" },
                ],
                last_bought_sportwear: [
                    { value: 1, label: "Less than 1 month ago" },
                    { value: 2, label: "Between 1 to 6 months ago" },
                    { value: 3, label: "Between 6 months to 1 year ago" },
                    { value: 4, label: "More than 1 year ago" },
                    { value: 5, label: "I do not remember" },
                ],
                purpose: [
                    { value: 1, label: "Gym/Fitness" },
                    { value: 2, label: "Running" },
                    { value: 3, label: "Hiking" },
                    { value: 4, label: "Outdoor fashion" },
                    { value: 5, label: "Sports (basketball, swimming etc)" },
                    { value: 'other', label: "Others" },
                ],
                buy_from: [
                    { value: 1, label: "Retail store" },
                    { value: 2, label: "Departmental store" },
                    { value: 3, label: "Multi brand retail" },
                    { value: 4, label: "Online" },
                    { value: 'other', label: "Others" },
                ],
                design: [
                    { value: 1, label: "Minimalist (with 1 or 2 colors)" },
                    { value: 2, label: "Bold colors and design (eg, neon colors)" },
                    { value: 3, label: "With slogans and pictures on" },
                    { value: 'other', label: "Others" },
                ],
                decision_buy: [
                    { value: 1, label: "Price" },
                    { value: 2, label: "Quality" },
                    { value: 3, label: "Value" },
                    { value: 4, label: "Brand" },
                    { value: 'other', label: "Others" },
                ],
                preferences: [
                    { value: 1, label: "Water resistance" },
                    { value: 2, label: "Cooling" },
                    { value: 3, label: "Anti bacteria" },
                    { value: 4, label: "Anti odour" },
                    { value: 5, label: "Soft and smooth material" },
                    { value: 6, label: "Elasticity" },
                    { value: 7, label: "Eudrance" },
                ],
            },

            scp_use_in_week: [],
            scp_use_to_learn: [],
            scp_factors_matter: [],
            scp_ingredients: [],
            scp_different_try: [],
            scp_do_you_spend: [],

            errors: []
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }

        let provider;
        if (web3Modal.cachedProvider) {
            provider = await web3Modal.connect()
        }

        if (provider) {
            this.provider = provider;
            const web3 = new Web3(provider);
            this.web3 = web3;
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                //Check For USDT Approve
                const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                    this.setState({ is_approve_usdt: 1, step: 2 })
                }
                const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${Number(97).toString(16)}`, }],
                });
                setTimeout(function () {

                }, 3000)
                return 0;
            }
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3 = web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1, step: 2 })
                    }
                    const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                    this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
    }

    /**
     * Save Survey
     */
    async saveData() {
        let that = this;
        that.setState({ isLoading: true });

        var errors_ = [];
        if (!that.state.gender || that.state.gender == null || that.state.gender == '') {
            errors_['gender'] = "This field is required!"
        }
        if (!that.state.age || that.state.age == null || that.state.age == '') {
            errors_['age'] = "This field is required!"
        }
        if (!that.state.martial_status || that.state.martial_status == null || that.state.martial_status == '') {
            errors_['martial_status'] = "This field is required!"
        }
        if (!that.state.annual_income_range || that.state.annual_income_range == null || that.state.annual_income_range == '') {
            errors_['annual_income_range'] = "This field is required!"
        }
        if (!that.state.employment_status || that.state.employment_status == null || that.state.employment_status == '') {
            errors_['employment_status'] = "This field is required!"
        }
        if (!that.state.education_level || that.state.education_level == null || that.state.education_level == '') {
            errors_['education_level'] = "This field is required!"
        }
        if (!that.state.exercise || that.state.exercise == null || that.state.exercise == '') {
            errors_['exercise'] = "This field is required!"
        }
        if (!that.state.sportswear_products || that.state.sportswear_products == null || that.state.sportswear_products == '') {
            errors_['sportswear_products'] = "This field is required!"
        }
        if (!that.state.last_bought_sportwear || that.state.last_bought_sportwear == null || that.state.last_bought_sportwear == '') {
            errors_['last_bought_sportwear'] = "This field is required!"
        }
        if (!that.state.purpose || that.state.purpose == null || that.state.purpose == '') {
            errors_['purpose'] = "This field is required!"
        }
        if (!that.state.buy_from || that.state.buy_from == null || that.state.buy_from == '') {
            errors_['buy_from'] = "This field is required!"
        }
        if (!that.state.design || that.state.design == null || that.state.design == '') {
            errors_['design'] = "This field is required!"
        }
        if (!that.state.decision_buy || that.state.decision_buy == null || that.state.decision_buy == '') {
            errors_['decision_buy'] = "This field is required!"
        }
        if (!that.state.preferences || that.state.preferences == null || that.state.preferences == '') {
            errors_['preferences'] = "This field is required!"
        }

        if (errors_) {
            that.setState({ errors: errors_ });
        } else {
            that.setState({ errors: [] });
        }

        var json = {
            gender: that.state.gender,
            age: that.state.age,
            martial_status: that.state.martial_status,
            annual_income_range: that.state.annual_income_range,
            employment_status: that.state.employment_status,
            education_level: that.state.education_level,
            exercise: that.state.exercise,
            sportswear_products: that.state.sportswear_products,
            last_bought_sportwear: that.state.last_bought_sportwear,
            purpose: that.state.purpose,
            buy_from: that.state.buy_from,
            design: that.state.design,
            decision_buy: that.state.decision_buy,
            preferences: that.state.preferences,
        }
        console.log(that.state.errors, json);

        this.completeSurvey(this.state.survey_id)
    }

    /**
     * Collect
     */
    async completeSurvey(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.survey_store,
            data: {
                survey: id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({ is_complete: true });
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /** 
     * Approve USDT 
     */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ isLoading: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        that.setState({ isLoading: 0, is_approve_usdt: 1, step: 2 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        toast.error(error.message);
                        that.setState({ isLoading: 0, step: 2 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing",
                    success: "Completed",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>{this.state.title}</h1>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.is_complete == false ?
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <form>
                                                    <div className="row">

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">1. What is your gender?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.gender.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="gender" id={"gender_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ gender: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"gender_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["gender"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">2. What is your age range?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.age.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="age" id={"age_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ age: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"age_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["age"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">3. What is your martial status?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.martial_status.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="martial_status" id={"martial_status_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ martial_status: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"martial_status_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["martial_status"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">4. What is your annual income range (USD)?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.annual_income_range.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="annual_income_range" id={"annual_income_range_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ annual_income_range: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"annual_income_range_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["annual_income_range"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">5. What is your employment status?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.employment_status.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="employment_status" id={"employment_status_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ employment_status: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"employment_status_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["employment_status"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">6. What is the highest level of education you have completed?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.education_level.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="education_level" id={"education_level_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ education_level: (e.target.value == "other") ? '' : e.target.value, education_level_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"education_level_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.education_level_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.education_level ?? ''}
                                                                        onChange={(e) => this.setState({ education_level: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["education_level"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">7. How often do you exercise?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.exercise.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="exercise" id={"exercise_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ exercise: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"exercise_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["exercise"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">8. How often do you use sportswear products?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.sportswear_products.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="sportswear_products" id={"sportswear_products_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ sportswear_products: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"sportswear_products_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["sportswear_products"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">9. When did you last bought a sportwear?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.last_bought_sportwear.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="last_bought_sportwear" id={"last_bought_sportwear_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ last_bought_sportwear: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"last_bought_sportwear_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["last_bought_sportwear"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">10. Which of the followings would describe your purpose to buy sportswear?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.purpose.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="purpose" id={"purpose_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ purpose: (e.target.value == "other") ? '' : e.target.value, purpose_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"purpose_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.purpose_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.purpose ?? ''}
                                                                        onChange={(e) => this.setState({ purpose: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["purpose"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">11. Where do you usually buy your sportswear from?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.buy_from.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="buy_from" id={"buy_from_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ buy_from: (e.target.value == "other") ? '' : e.target.value, buy_from_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"buy_from_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.buy_from_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.buy_from ?? ''}
                                                                        onChange={(e) => this.setState({ buy_from: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["buy_from"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">12. What is your preferred design for sportswear?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.design.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="design" id={"design_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ design: (e.target.value == "other") ? '' : e.target.value, design_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"design_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.design_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.design ?? ''}
                                                                        onChange={(e) => this.setState({ design: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["design"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">13. Which of the followings would influence your decision to buy a certain brand?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.decision_buy.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="decision_buy" id={"decision_buy_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ decision_buy: (e.target.value == "other") ? '' : e.target.value, decision_buy_is_other: (e.target.value == "other") ? true : false })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"decision_buy_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(this.state.decision_buy_is_other) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.decision_buy ?? ''}
                                                                        onChange={(e) => this.setState({ decision_buy: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["decision_buy"]}</span>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">14. Which of the below type is your preferences for sportswear materials?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.preferences.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="preferences" id={"preferences_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ preferences: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"preferences_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["preferences"]}</span>
                                                        </div>

                                                    </div>

                                                    <div className="form-group mt-3">
                                                        {
                                                            this.state.isLoading ?
                                                                <button type="button" className="btn btn-primary me-2">
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    Loading...
                                                                </button>
                                                                :
                                                                this.state.is_approve_usdt ?
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.saveData()}>Complete Now</button>
                                                                    :
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.approveUSDT()}>Enrol Now</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <div className="col text-center">
                                                    <h1>{this.state.title}</h1>
                                                    <img src={api.SiteURL + "assets/images/success.svg"} style={{ height: 100 }} />
                                                    <p>Thank you</p>
                                                    <small>Your Response is recorded</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </>
                <Footer />
            </>
        );
    }
}
function withRouter(Child) {
    return (props) => {
        const params = useParams();
        return <Child {...props} params={params} />;
    }
}
export default withRouter(SportswearSurvey);
