import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class HappyDeal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>Promotions</h1>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="promotion">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="events">
                                        <div>
                                            <div className="box">
                                                <picture>
                                                    <img src="assets/images/pto.jpg" alt="" />
                                                </picture>
                                                <h4>Promotion01</h4>
                                                <span>May 22, 2023</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...</p>
                                                <a href="#" className="btn btn-primary py-1 px-4">Read more</a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="box">
                                                <picture>
                                                    <img src="assets/images/pto.jpg" alt="" />
                                                </picture>
                                                <h4>Promotion02</h4>
                                                <span>May 22, 2023</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...</p>
                                                <a href="#" className="btn btn-primary py-1 px-4">Read more</a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="box">
                                                <picture>
                                                    <img src="assets/images/pto.jpg" alt="" />
                                                </picture>
                                                <h4>Promotion02</h4>
                                                <span>May 22, 2023</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...</p>
                                                <a href="#" className="btn btn-primary py-1 px-4">Read more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <div className="friend">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <h1>Refer a friend</h1>
                                </div>
                                <div className="col-3">
                                    <figure>
                                        <img src="assets/images/wjs_03.png" alt="" />
                                    </figure>
                                    <h4>Refer friend</h4>
                                </div>
                                <div className="col-6">
                                    <figure>
                                        <img src="assets/images/reward.png" alt="" />
                                    </figure>
                                    <h3>Earn Dex Rewards</h3>
                                </div>
                                <div className="col-3">
                                    <figure>
                                        <img src="assets/images/wjs_03.png" alt="" />
                                    </figure>
                                    <h4>Refer friend</h4>
                                </div>
                                <div className="col-12 mt-5 mb-4">
                                    <h2>Can’t wait to start?</h2>
                                    <p>Login now to refer your friends!</p>
                                    <a href="#" className="btn btn-primary btn-connect m-0 rounded-pill">CONNECT WALLET</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                <Footer />
            </>
        );
    }
}
export default HappyDeal;
