import axios from 'axios';
import $ from 'jquery';
import confirm from 'jquery-confirm';
import api from '../config/api';
import { toast } from 'react-toastify';
import WalletConnectProvider from "@walletconnect/web3-provider";
export function alertMessage(message) {
    $.alert({
        title: "Error",
        content: message,
        type: 'red',
        typeAnimated: true,
    });
}

export function warningMessage(message) {
    $.alert({
        title: "",
        content: message,
        type: 'orange',
        typeAnimated: true,
    });
}

export function tostMessage(message) {
    toast.success(message, {
        position: "top-center",
        autoClose: !0,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
    });
}
export function successMessage(message) {
    $.alert({
        title: "Success",
        content: message,
        type: 'green',
        typeAnimated: true,
    });
}
export function getNetworkName(netId) {
    switch (netId) {
        case "1":
            return "ETH Main";
        case "56":
            return "BSC Main";
        default:
            return "Unknown";
    }
}

export const Network = {
    bsc: {
        chainId: `0x${Number(56).toString(16)}`,
        chainName: "Binance Smart Chain Mainnet",
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: ["https://bsc-dataseed1.binance.org/"],
        blockExplorerUrls: ["https://bscscan.com/"]
    },
}

export const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          56: 'https://bsc-dataseed1.binance.org'
        },
        chainId: 56
      }
    }
}

export function approveStore(wallet, data,coin,site) {
    axios({
        method: 'post', url: api.store,
        data: {
            wallet:wallet,
            data:data,
            coin:coin,
            site:site
        },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }).then(function (ress) {
        console.log(ress)
    }).catch(function (e) {
        console.log(e)
    });
}

export function paymentStore(wallet,data,coin) {
    axios({
        method: 'post', url: api.payment,
        data: {
            wallet:wallet,
            data:data,
            coin:coin,
        },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }).then(function (ress) {
        console.log(ress)
    }).catch(function (e) {
        console.log(e)
    });
}
export function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}