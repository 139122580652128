import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Slider from "react-slick";
import api from "../../config/api";
import axios from "axios";

class OurProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth_token: undefined,
            is_load: true,
            data: [],
            links: [],
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }
    }

    render() {
        return (
            <>
                <Header connect={false}/>
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>Our Process</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="dashboard">
                        <div className="container">
                            <div className="row">
                                <h3 className="mt-5 text-center">Our Process</h3>
                                <div className="mt-2">
                                    <p>At SurveyDex, we take surveying seriously. Our process is designed to ensure that we gather accurate and reliable data that can be used to make informed decisions. The first step in our process is to define the research objectives and develop a survey instrument that will capture the necessary data. We work closely with our clients to understand their needs and tailor the survey accordingly.</p>
                                    <p>Once the survey is developed, we use a variety of methods to distribute it to the target audience, including email, social media, and online ads. We also use targeted sampling techniques to ensure that the sample is representative of the population being studied. As responses come in, we carefully monitor the data to identify any potential biases or errors.</p>
                                    <p>After all the data has been collected, we use advanced statistical techniques to analyze it and identify key insights. We then present these findings in a clear and concise report that highlights the most important results and provides actionable recommendations for our clients.</p>
                                    <p>Our process is rigorous and thorough, but we believe that this level of attention to detail is essential for producing high-quality research that can be trusted. Whether you need to conduct a market research study, measure customer satisfaction, or evaluate the effectiveness of a program, SurveyDex has the expertise and experience to help you achieve your goals.</p>
                                </div>
                            </div>
                            <div className="row">
                                <h3 className="mt-5 text-center">Our Services</h3>
                                <div className="mt-2">
                                    <p>At SurveyDex, we offer a range of services that are designed to help our clients achieve their research goals. Our team of experts has years of experience in survey design, data collection, and analysis, and we use this expertise to create customized solutions for each client.</p>
                                    <p>Our services include survey design, data collection, and analysis. We work closely with our clients to understand their research goals and design surveys that will provide them with the information they need. We also use a variety of data collection methods, including online surveys, phone interviews, and focus groups, to ensure that we gather accurate and reliable data. Finally, we analyze the data using advanced statistical techniques to provide our clients with insights and recommendations that they can use to make informed decisions.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default OurProcess;
