import React from "react";
import './../App.css';
import { NavLink } from "react-router-dom";

import './../App.css';
import Web3 from "web3";
import { NODE_ABI, NODE_URL } from './../config/bsc_node';
import { providerOptions, alertMessage, number_format, approveStore, paymentStore, Network } from './../components/Util';
import { USDT_ADDRESS, USDT_ABI, NODE_EXPLORER } from './../config/bsc_usdt';
import { NODE_ADDRESS } from './../config/bsc_node';
import Web3Modal from "web3modal";
import { toast } from 'react-toastify';
import axios from "axios";
import api from "../config/api";

import Modal from 'react-bootstrap/Modal';


const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeClass: "",
            account: null,
            is_connected: false,
            usdt_balance: 0,
            order_id: "",
            api_key: "",
            payment_amount: "",
            step: 1,
            onpress: false,
            is_approve_usdt: 0,

            modal_show: false,
        }
    }

    async componentDidMount() {
        if(this.props.connect==false){

        }
        else{
            this.loadBSC();
        }
        
        const queryParams = new URLSearchParams(window.location.search)
        const refcode = queryParams.get("refcode")
        if(refcode!=null){
            localStorage.setItem('survey_refcode',refcode);
        }
        let sponsor=localStorage.getItem('survey_refcode');
        if(sponsor==null || sponsor==undefined){

        }
        else{
            this.setState({sponsor:sponsor})
        }
    }

    /**
     * Load BSC
     */
    async loadBSC() {
        const web3ModalBSC = new Web3Modal({
            network: "binance",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        let provider;
        if (web3ModalBSC.cachedProvider) {
            provider = await web3ModalBSC.connect()
        }
        else {
            provider = await web3ModalBSC.connect();
        }
        if (provider) {
            this.provider = provider;
            const web3 = new Web3(this.provider);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ is_connected: true, account: accounts[0] })
                let auth_token = localStorage.getItem('auth_token');
                if (auth_token) {
                    this.setState({ auth_token: auth_token })
                    this.setState({ isLoggedIn: true })
                    this.getBalance(accounts[0], auth_token);
                }
                else {
                    this.getBalance(accounts[0]);
                }
            }
        }
    }
    async doConnect() {
        const web3ModalBSC = new Web3Modal({
            network: "mainnet",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        const provider = await web3ModalBSC.connect();
        if (provider) {
            try {
                this.provider = provider;
                const web3 = new Web3(provider);
                this.web3 = web3;
                let accounts = await web3.eth.getAccounts();
                if (accounts.length) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true, blockchain: "BSC" })
                    this.doSignBSC();
                }
            } catch (error) {
                if (error.code === 4001) {
                    alert(error.message);
                }
                else {
                    alert("Install Metamask or Other Wallet");
                }
            }
        } else {
            alert("Install Metamask or Other Wallet");
        }
    }

    /**
     * Do Sign BSC
     */
    async doSignBSC() {
        let that = this;
        that.setState({ isLoading: true })
        const web3ModalBSC = new Web3Modal({
            network: "mainnet",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        let provider;
        if (web3ModalBSC.cachedProvider) {
            provider = await web3ModalBSC.connect()
        }
        else {
            provider = await web3ModalBSC.connect();
        }
        if (this.state.is_connected) {
            const web3 = new Web3(provider);
            const string = web3.utils.sha3("THE_DEX_SURVEY_PROJECT");
            const accounts = await web3.eth.getAccounts();
            new Promise((resolve, reject) => {
                web3.eth.personal.sign(string, accounts[0], "test").then(async function (data) {
                    that.login(accounts[0], data, 'BSC');
                    localStorage.setItem("chain", "BSC")
                    that.setState({ isLoading: false })
                    resolve(true);
                });
            });
        }
        else {
            alert("Connect Wallet");
        }
    }

    /**
     * Check Balance for Login
     */
    async getBalance(wallet, auth_token, open_modal = false) {
        let that = this;
        await axios({
            method: 'post', url: api.details,
            data: {
                wallet: wallet,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + auth_token,
            }
        }).then(function (ress) {
            //console.log("Balance",ress.data);
            if (ress.data.error === 200) {
                that.setState({
                    username: ress.data.username,
                    balance: ress.data.balance,
                })
                localStorage.setItem("user", JSON.stringify(ress.data))
                if (open_modal) {
                    window.location.reload();
                }
            }
            //Refcode
            else if (ress.data.error === 203) {
                that.setState({
                    username: ress.data.username,
                    balance: ress.data.balance,
                    step: 3,
                })
                that.setState({ modal_show: true });
                localStorage.setItem("user", JSON.stringify(ress.data))
            }
            else {
                that.setState({ modal_show: true });
            }
        }).catch(function (e) {
            console.log(e);
            if (e.response.status === 401) {
                that.doSignBSC();
            }
        });
    }

    /**
     * Login
     */
    async login(wallet, secret, device) {
        let that = this;
        await axios({
            method: 'post', url: api.register,
            data: {
                wallet: wallet,
                secret: secret,
                device_name: device
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(function (ress) {
            localStorage.setItem("auth_token", ress.data);
            that.setState({ "auth_token": ress.data })
            that.getBalance(wallet, ress.data, true);
        }).catch(function (e) {
            console.log(e);
        });
    }

    /**
     * Edit Name
     */
    async editName() {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.edit_name,
            data: {
                name: this.state.name,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({ step: 3 })
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /**
     * Apply Refcode
     */
    async applyRefcode(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.add_member_tree,
            data: {
                sponsor: id == 0 ? this.state.sponsor : "company",
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                window.location.reload();
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    doLogOff() {
        this.setState({ isLoading: true });
        localStorage.clear();
        setTimeout(function () {
            window.location.reload();
        }, 2000)
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container position-relative">
                        <NavLink to={"/"} className="navbar-brand" >
                            <img src={api.SiteURL + "assets/images/logo.png"} alt="" />
                        </NavLink>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/about-us"} className="nav-link">About us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/our-process"} className="nav-link">Our Process</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/dashboard"} className="nav-link" >Dashboard</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/articles"} className="nav-link" >Articles</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/reward"} className="nav-link" >Reward</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/faqs"} className="nav-link" >Faqs</NavLink>
                                    {/* <NavLink activeclass="active" to={"/happy-deal"} className="nav-link" >Happy Deals</NavLink> */}
                                </li>
                            </ul>
                            <form className="d-flex position-relative me-3" role="search">
                                <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-link position-absolute top-0 end-0 me-1" type="submit">
                                    <img src="assets/images/icn_search.svg" alt="" />
                                </button>
                            </form>
                        </div>
                        {
                            this.state.is_connected ?
                                <a className="btn btn-danger px-3" onClick={() => this.doLogOff()}>Disconnect</a>
                                :
                                <a className="btn btn-primary px-3" onClick={() => this.doConnect()}>Connect Wallet</a>
                        }

                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-body">
                            <form className="d-flex mt-5 mb-3 position-relative" role="search">
                                <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-link position-absolute top-0 end-0" type="submit">
                                    <img src="assets/images/icn_search.svg" alt="" />
                                </button>
                            </form>
                            <ul className="navbar-nav flex-grow-1">
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/about-us"} className="nav-link">About us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/our-process"} className="nav-link">Our Process</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/dashboard"} className="nav-link" >Dashboard</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/articles"} className="nav-link" >Articles</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/reward"} className="nav-link" >Reward</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeclass="active" to={"/faqs"} className="nav-link" >Faqs</NavLink>
                                    {/* <NavLink activeclass="active" to={"/happy-deal"} className="nav-link" >Happy Deals</NavLink> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <Modal
                    show={this.state.modal_show}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Body>
                        {
                            this.state.step == 1 ?
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-10 mx-auto text-center">
                                        <b>{this.state.account}</b>
                                        <br />
                                        {
                                            this.state.isLoading ?
                                                <button type="button" className="btn btn-primary me-2">
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                                :
                                                <button type="button" className="btn btn-primary me-2" onClick={() => this.doSignBSC()}>Sign In</button>
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            this.state.step == 3 ?
                                <>
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-10 mx-auto text-center">
                                            <form>
                                                <h1>Apply Refcode</h1>
                                                <div className="mb-3">
                                                    <input className="form-control display" type="text" placeholder="Enter Refcode" value={this.state.sponsor ?? ''} onChange={(e) => this.setState({ sponsor: e.target.value })} />
                                                </div>
                                                {
                                                    this.state.isLoading ?
                                                        <button type="button" className="btn btn-primary me-2">
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                            Loading...
                                                        </button>
                                                        :
                                                        <button type="button" className="btn btn-primary me-2" onClick={() => this.applyRefcode(0)}>Submit</button>
                                                }
                                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.applyRefcode(1)}>Skip</button>
                                            </form>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Header;