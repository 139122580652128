import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";

import { toast } from "react-toastify";
import { CHAIN_ID, NODE_ADDRESS, NODE_URL } from "../../config/bsc_node";
import { USDT_ABI, USDT_ADDRESS } from "../../config/bsc_usdt";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useParams } from "react-router-dom";

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                56: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}
const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});
class FashionSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: props.params.survey_id,
            title: props.params.title,
            account: "",
            is_approve_usdt: 0,
            is_connected:false,
            auth_token: undefined,
            is_complete:false,

            options: {
                clothes: [
                    { value: 1, label: "Multiple times in a week" },
                    { value: 2, label: "Once a week" },
                    { value: 3, label: "Couple of times in a month" },
                    { value: 4, label: "Once a month" },
                ],
                new_clothes: [
                    { value: 1, label: "The current clothes getting old" },
                    { value: 2, label: "I like wearing new clothes" },
                    { value: "other", label: "Others (Please specify)" },
                ],
                buy_most: [
                    { value: 1, label: "Shoes" },
                    { value: 2, label: "Clothes" },
                    { value: 3, label: "Accessories" },
                    { value: "other", label: "Others (Please specify)" },
                ],
                suits_you: [
                    { value: 1, label: "I like wearing new clothes for myself" },
                    { value: 2, label: "I like to impress people by wearing new clothes" },
                ],
                fashion_trends: [
                    { value: 1, label: "Never" },
                    { value: 2, label: "Sometimes" },
                    { value: 3, label: "Usually" },
                    { value: 4, label: "Always" },
                ],
                clothes_because_branding: [
                    { value: 1, label: "Yes" },
                    { value: 2, label: "No" },
                    { value: 3, label: "Sometimes" },
                ],
                clothing_style: [
                    { value: 1, label: "No" },
                    { value: 2, label: "Sometimes" },
                    { value: 3, label: "Yes" },
                ],
                same_clothes_with_someone_else: [
                    { value: 1, label: "Yes" },
                    { value: 2, label: "Sometimes" },
                    { value: 3, label: "No" },
                ],
                personality_related: [
                    { value: 1, label: "Yes" },
                    { value: 2, label: "Maybe" },
                    { value: 3, label: "No" },
                ],
            },
            errors: []
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }

        let provider;
        if (web3Modal.cachedProvider) {
            provider = await web3Modal.connect()
        }
        
        if (provider) {
            this.provider = provider;
            const web3 = new Web3(provider);
            this.web3 = web3;
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                //Check For USDT Approve
                const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                    this.setState({ is_approve_usdt: 1, step: 2 })
                }
                const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${Number(97).toString(16)}`, }],
                });
                setTimeout(function () {

                }, 3000)
                return 0;
            }
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3 = web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1, step: 2 })
                    }
                    const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                    this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
    }

    /**
     * Save Survey
     */
    async saveData() {
        let that = this;
        that.setState({ isLoading: true });

        var errors_ = [];
        if (!that.state.age || that.state.age == null || that.state.age == '') {
            errors_['age'] = "This field is required!"
        }
        if (!that.state.gender || that.state.gender == null || that.state.gender == '') {
            errors_['gender'] = "This field is required!"
        }
        if (!that.state.clothes || that.state.clothes == null || that.state.clothes == '') {
            errors_['clothes'] = "This field is required!"
        }
        if (!that.state.new_clothes || that.state.new_clothes == null || that.state.new_clothes == '') {
            errors_['new_clothes'] = "This field is required!"
        }
        if (!that.state.buy_most || that.state.buy_most == null || that.state.buy_most == '') {
            errors_['buy_most'] = "This field is required!"
        }
        if (!that.state.suits_you || that.state.suits_you == null || that.state.suits_you == '') {
            errors_['suits_you'] = "This field is required!"
        }
        if (!that.state.fashion_trends || that.state.fashion_trends == null || that.state.fashion_trends == '') {
            errors_['fashion_trends'] = "This field is required!"
        }
        if (!that.state.clothes_because_branding || that.state.clothes_because_branding == null || that.state.clothes_because_branding == '') {
            errors_['clothes_because_branding'] = "This field is required!"
        }
        if (!that.state.clothing_style || that.state.clothing_style == null || that.state.clothing_style == '') {
            errors_['clothing_style'] = "This field is required!"
        }
        if (!that.state.same_clothes_with_someone_else || that.state.same_clothes_with_someone_else == null || that.state.same_clothes_with_someone_else == '') {
            errors_['same_clothes_with_someone_else'] = "This field is required!"
        }
        if (!that.state.personality_related || that.state.personality_related == null || that.state.personality_related == '') {
            errors_['personality_related'] = "This field is required!"
        }
        if (!that.state.clothing_monthly || that.state.clothing_monthly == null || that.state.clothing_monthly == '') {
            errors_['clothing_monthly'] = "This field is required!"
        }

        if (errors_) {
            that.setState({ errors: errors_ });
        } else {
            that.setState({ errors: [] });
        }

        var json = {
            age: that.state.age,
            gender: that.state.gender,
            clothes: that.state.clothes,
            new_clothes: that.state.new_clothes,
            buy_most: that.state.buy_most,
            suits_you: that.state.suits_you,
            fashion_trends: that.state.fashion_trends,
            clothes_because_branding: that.state.clothes_because_branding,
            clothing_style: that.state.clothing_style,
            same_clothes_with_someone_else: that.state.same_clothes_with_someone_else,
            personality_related: that.state.personality_related,
            clothing_monthly: that.state.clothing_monthly,
        }
        console.log(that.state.errors, json);

        this.completeSurvey(this.state.survey_id)
    }

    /**
     * Collect
     */
    async completeSurvey(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.survey_store,
            data: {
                survey: id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({is_complete:true});
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /** 
     * Approve USDT 
     */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ isLoading: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        that.setState({ isLoading: 0, is_approve_usdt: 1, step: 2 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        toast.error(error.message);
                        that.setState({ isLoading: 0, step: 2 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing",
                    success: "Completed",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }
    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>{this.state.title}</h1>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.is_complete==false?
                    <section className="dashboard">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="form_container_">
                                    <div className="events box">
                                        <form>
                                            <div className="row">

                                                <div className="form-group mt-3">
                                                    <label className="question">1. What is your age?</label>
                                                    <input
                                                        className="form-control display" type="text"
                                                        value={this.state.age ?? ''}
                                                        onChange={(e) => this.setState({ age: e.target.value })}
                                                    />
                                                    <span className="error">{this.state.errors["age"]}</span>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <label className="question">2. What is your gender?</label>
                                                    <input
                                                        className="form-control display" type="text"
                                                        value={this.state.gender ?? ''}
                                                        onChange={(e) => this.setState({ gender: e.target.value })}
                                                    />
                                                    <span className="error">{this.state.errors["gender"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">3 How often do you buy clothes?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.clothes.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="clothes" id={"clothes_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ clothes: e.target.value })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"clothes_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <span className="error">{this.state.errors["clothes"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">4. Why do you buy new clothes?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.new_clothes.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="new_clothes" id={"new_clothes_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ new_clothes: (e.target.value == "other") ? '' : e.target.value, new_clothes_is_other: (e.target.value == "other") ? true : false })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"new_clothes_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {(this.state.new_clothes_is_other) ?
                                                        <div className="ml-4">
                                                            <input
                                                                className="form-control display" type="text"
                                                                value={this.state.new_clothes ?? ''}
                                                                onChange={(e) => this.setState({ new_clothes: e.target.value })}
                                                            />
                                                        </div>
                                                        : null}
                                                    <span className="error">{this.state.errors["new_clothes"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">5. Which type of the below do you buy most?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.buy_most.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="buy_most" id={"buy_most_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ buy_most: (e.target.value == "other") ? '' : e.target.value, buy_most_is_other: (e.target.value == "other") ? true : false })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"buy_most_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {(this.state.buy_most_is_other) ?
                                                        <div className="ml-4">
                                                            <input
                                                                className="form-control display" type="text"
                                                                value={this.state.buy_most ?? ''}
                                                                onChange={(e) => this.setState({ buy_most: e.target.value })}
                                                            />
                                                        </div>
                                                        : null}
                                                    <span className="error">{this.state.errors["buy_most"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">6. Which one of the below suits you?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.suits_you.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="suits_you" id={"suits_you_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ suits_you: e.target.value })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"suits_you_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <span className="error">{this.state.errors["suits_you"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">7. Do you follow fashion trends?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.fashion_trends.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="fashion_trends" id={"fashion_trends_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ fashion_trends: e.target.value })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"fashion_trends_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <span className="error">{this.state.errors["fashion_trends"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">8. Do you buy clothes because of the branding?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.clothes_because_branding.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="clothes_because_branding" id={"clothes_because_branding_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ clothes_because_branding: e.target.value })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"clothes_because_branding_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <span className="error">{this.state.errors["clothes_because_branding"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">9. Do you evaluate people according to their clothing style?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.clothing_style.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="clothing_style" id={"clothing_style_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ clothing_style: e.target.value })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"clothing_style_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <span className="error">{this.state.errors["clothing_style"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">10. Is it okay for you to wear the same clothes with someone else?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.same_clothes_with_someone_else.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="same_clothes_with_someone_else" id={"same_clothes_with_someone_else_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ same_clothes_with_someone_else: e.target.value })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"same_clothes_with_someone_else_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <span className="error">{this.state.errors["same_clothes_with_someone_else"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="mb-2 question">11. Do you believe clothing and personality are related to each other?</label>
                                                    <div className="ml-4">
                                                        {this.state.options.personality_related.map((data) => (
                                                            <div className="form-check" key={data.label}>
                                                                <input className="form-check-input" type="radio" name="personality_related" id={"personality_related_" + data.value}
                                                                    value={data.value}
                                                                    onChange={(e) => this.setState({ personality_related: e.target.value })}
                                                                />
                                                                <label className="form-check-label" htmlFor={"personality_related_" + data.value}>
                                                                    {data.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <span className="error">{this.state.errors["personality_related"]}</span>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label className="question">12. How much do you spend on clothing monthly?</label>
                                                    <input
                                                        className="form-control display" type="text"
                                                        value={this.state.clothing_monthly ?? ''}
                                                        onChange={(e) => this.setState({ clothing_monthly: e.target.value })}
                                                    />
                                                    <span className="error">{this.state.errors["clothing_monthly"]}</span>
                                                </div>

                                            </div>

                                            <div className="form-group mt-3">
                                                {
                                                    this.state.isLoading ?
                                                        <button type="button" className="btn btn-primary me-2">
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                            Loading...
                                                        </button>
                                                        :
                                                        this.state.is_approve_usdt ?
                                                            <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.saveData()}>Complete Now</button>
                                                            :
                                                            <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.approveUSDT()}>Enrol Now</button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="dashboard">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="form_container_">
                                        <div className="events box">
                                            <div className="col text-center">
                                                <h1>{this.state.title}</h1>
                                                <img src={api.SiteURL+"assets/images/success.svg"} style={{height:100}}/>
                                                <p>Thank you</p>
                                                <small>Your Response is recorded</small>
                                            </div>          
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </section>
                }
                </>
                <Footer />
            </>
        );
    }
}
function withRouter(Child) {
    return (props) => {
        const params = useParams();
        return <Child {...props} params={params} />;
    }
}
export default withRouter(FashionSurvey);
