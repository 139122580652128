const BaseURL = 'https://survey.creationpro.vip/';
const SiteURL = 'https://surveydex.creationpro.vip/';

//const BaseURL = 'http://localhost:8080/';
//const SiteURL = 'https://localhost:3000/';

const api = {    
    BaseURL:BaseURL,
    SiteURL:SiteURL,
    SurveyImage:BaseURL+"storage/survey/image/",
    NewsImage:BaseURL+"storage/news/image/",
    //Public

    //Auth
    login:BaseURL +'api/login',
    register:BaseURL +'api/register',
    details:BaseURL +'api/user/account/details',
    edit_email:BaseURL +"api/user/account/email",

    //Report
    dex_report:BaseURL +'api/user/report/dex',

    //Apply Refcode
    check_member:BaseURL +'api/user/account/refcode/check',
    add_member_tree:BaseURL +'api/user/account/refcode/apply',

    //Friend List
    friend_list:BaseURL +'api/user/friend-list',

    //Survey
    survey_list:BaseURL +'api/survey',
    survey_store:BaseURL +'api/user/survey/store',
    survey_my_list:BaseURL +'api/user/survey/user',

    //Artical
    artical:BaseURL +'api/artical',

    //Withdrawal
    withdraw:BaseURL +'api/user/withdraw',

};
export default api;