

import React from "react";
import { BrowserRouter, Route, Routes, } from 'react-router-dom';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

import Home from './pages/index';
import Dashboard from "./pages/Dashboard";
import HappyDeal from "./pages/HappyDeal";
import Reward from "./pages/Reward";
import RewardHistory from "./pages/Reward/history";
import Article from "./pages/Article";
import Aboutus from "./pages/Aboutus";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq";
import FriendList from "./pages/Friends";
import Report from "./pages/Report";
import LuxuryBagSurvey from "./pages/Forms/LuxuryBagSurvey";
import FashionSurvey from "./pages/Forms/FashionSurvey";
import LuxuryWatchSurvey from "./pages/Forms/LuxuryWatchSurvey";
import SkincareSurvey from "./pages/Forms/SkincareSurvey";
import ElectricCarSurvey from "./pages/Forms/ElectricCarSurvey";
import SportswearSurvey from "./pages/Forms/SportswearSurvey";
import LuxuryCarSurvey from "./pages/Forms/LuxuryCarSurvey";
import OurProcess from "./pages/OurProcess";

window.process = {};
class App extends React.Component {

  componentDidMount() {
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            rtl={false}
          />

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/friends" element={<FriendList />} />
            <Route exact path="/report" element={<Report />} />
            <Route exact path="/reward-history" element={<RewardHistory />} />
            <Route exact path="/happy-deal" element={<HappyDeal />} />
            <Route exact path="/reward" element={<Reward />} />
            <Route exact path="/articles" element={<Article />} />
            <Route exact path="/about-us" element={<Aboutus />} />
            <Route exact path="/terms-and-conditions" element={<TermsConditions />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/faqs" element={<Faq />} />
            <Route exact path="/survey-luxury-bag/:title/:id" element={<LuxuryBagSurvey />} />
            <Route exact path="/survey-fashion/:title/:id" element={<FashionSurvey />} />
            <Route exact path="/survey-luxury-watch/:title/:id" element={<LuxuryWatchSurvey />} />
            <Route exact path="/survey-skincare/:title/:id" element={<SkincareSurvey />} />
            <Route exact path="/survey-electric-car/:title/:id" element={<ElectricCarSurvey />} />
            <Route exact path="/survey-luxury-car/:title/:id" element={<LuxuryCarSurvey />} />
            <Route exact path="/survey-sportswear/:title/:id" element={<SportswearSurvey />} />
            <Route exact path="/our-process" element={<OurProcess />} />
          </Routes>

        </BrowserRouter>
      </>
    );
  }
}
export default App;