import React from "react";
import { NavLink } from "react-router-dom";
import api from "../config/api";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-md-4 order-md-2">
                            <a><img src="assets/images/facebook.svg" alt="" /></a>
                            <a><img src="assets/images/whatsapp.svg" alt="" /></a>
                            <a><img src="assets/images/twitter.svg" alt="" /></a>
                            <a><img src="assets/images/telegram.svg" alt="" /></a>
                        </div>
                        <div className="col order-md-1">
                            <div className="d-none d-md-block">
                                <NavLink to={"/terms-and-conditions"}>Terms & Conditions</NavLink>
                                <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
                                <NavLink to={"/contact-us"}>Contact Us</NavLink>
                                <NavLink to={"/faqs"}>FAQS</NavLink>
                            </div>
                            Copyright &copy; 2023 SurveyDex.com All rights reserved
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;