import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import api from "../../config/api";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { number_format } from "../../components/Util";
import { NavLink } from "react-router-dom";
import CopyToClipboard from 'react-copy-to-clipboard';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            username: "",
            balance: "0.00",
            email: "",
            wallet: "",
            modal_email: false,
            frinds: "0",
            survey: "0",
        }
    }

    async componentDidMount() {
        let user_id = localStorage.getItem('user');
        if (user_id) {
            let user = JSON.parse(user_id);
            this.getBalance(user.wallet)
        }
    }

    /**
     * Check Balance for Login
     */
    async getBalance(wallet) {
        let that = this;
        await axios({
            method: 'post', url: api.details,
            data: {
                wallet: wallet,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            //console.log("Balance",ress.data);
            if (ress.data.error === 200) {
                that.setState({
                    email: ress.data.email ? ress.data.email.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c) : null,
                    username: ress.data.username,
                    balance: ress.data.balance,
                    wallet: ress.data.wallet,
                    friends: ress.data.friends,
                    survey: ress.data.statistic.total_survey,
                })
                localStorage.setItem("user", JSON.stringify(ress.data))
            }
        }).catch(function (e) {
            console.log(e);
            if (e.response.status === 401) {
                that.doSignBSC();
            }
        });
    }

    /**
     * Save E-Mail
     */
    async saveEmail() {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.edit_email,
            data: {
                email: this.state.email,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                window.location.reload();
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /**
     * Collect
     */
    async collectReward() {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.withdraw,
            data: {
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>Dashboard</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="dashboard">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="box">
                                        <div className="row align-items-center mb-4">
                                            <div className="col-3">
                                                <img src="assets/images/user.png" alt="" />
                                            </div>
                                            <div className="col-7">
                                                <p>Profile<br />
                                                    <span>{this.state.username}</span></p>
                                            </div>
                                            <div className="col-2">

                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-4">
                                            <div className="col-3">
                                                <img src="assets/images/user.png" alt="" />
                                            </div>
                                            <div className="col-7">
                                                <p>Email Adress<br />
                                                    <span>{this.state.email}</span></p>
                                            </div>
                                            <div className="col-2">
                                                <a onClick={() => this.setState({ modal_email: true })} className="clickable"><img src="assets/images/edit.png" alt="" /></a>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-3">
                                                <img src="assets/images/user.png" alt="" />
                                            </div>
                                            <div className="col-7">
                                                <p>Wallet Address<br />
                                                    <span>******{this.state.wallet.substring(this.state.wallet.length - 5)}</span></p>
                                            </div>
                                            <div className="col-2">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="box">
                                        <div className="row align-items-center mb-4">
                                            <div className="col-3">
                                                <img src="assets/images/user.png" alt="" />
                                            </div>
                                            <div className="col-7">
                                                <p>Survey Completed<br />
                                                    <span>{this.state.survey}</span></p>
                                            </div>
                                            <div className="col-2">
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-4">
                                            <div className="col-3">
                                                <img src="assets/images/user.png" alt="" />
                                            </div>
                                            <div className="col-7">
                                                <p>Survey Status<br />
                                                    <span><NavLink activeclass="active" to={"/reward-history"} className="nav-link color-primary" >View</NavLink></span></p>
                                            </div>
                                            <div className="col-2">
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-3">
                                                <img src="assets/images/user.png" alt="" />
                                            </div>
                                            <div className="col-6">
                                                <p>Dex Rewards<br />
                                                    <span>{number_format(this.state.balance, 2, ".", ",")} USDT
                                                        <NavLink activeclass="active" to={"/report"} className="nav-link color-primary" >View</NavLink></span>
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                {
                                                    this.state.isLoading ?
                                                        <>
                                                            <p>
                                                                <span className="clickable">
                                                                    <u>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    </u>
                                                                </span>
                                                            </p>
                                                        </>
                                                        :
                                                        <p><span className="clickable" onClick={() => this.collectReward()}><u>Collect</u></span></p>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="box">
                                        <div className="row align-items-center mb-4">
                                            <div className="col-3">
                                                <img src="assets/images/user.png" alt="" />
                                            </div>
                                            <div className="col-7">
                                                <p>Friend Lists<br />
                                                    <span>{this.state.friends}</span>
                                                    &nbsp;&nbsp;<span className="clickable">
                                                        <NavLink activeclass="active" to={"/friends"} className="nav-link" >View</NavLink>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="col-2">
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-4">
                                            <div className="col-3">
                                                <img src="assets/images/user.png" alt="" />
                                            </div>
                                            <div className="col-7">
                                                <p>Referral Code <br />
                                                    <CopyToClipboard text={api.SiteURL+"?refcode="+this.state.username} onCopy={() => toast.success("Copied")}>
                                                        <span style={{cursor:"pointer",color: "#0a8ef0"}}>Copy</span>
                                                    </CopyToClipboard>
                                                </p>
                                            </div>
                                            <div className="col-2">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                <Footer />

                <Modal
                    show={this.state.modal_email}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Body>
                        <>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-10 mx-auto text-center">
                                    <form>
                                        <h3>Update E-Mail Address</h3>
                                        <div className="mb-3">
                                            <input className="form-control display" type="text" placeholder="Enter E-Mail Address" value={this.state.email ?? ''} onChange={(e) => this.setState({ email: e.target.value })} />
                                        </div>
                                        {
                                            this.state.isLoading ?
                                                <button type="button" className="btn btn-primary me-2">
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                                :
                                                <button type="button" className="btn btn-primary me-2" onClick={() => this.saveEmail()}>Submit</button>
                                        }
                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({ modal_email: false })}>Close</button>
                                    </form>
                                </div>
                            </div>
                        </>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default Dashboard;
