import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>Privacy Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="dashboard">
                        <div className="container">
                            <p>
                                Thank you for choosing SurveyDex, a product offered by SurveyDex ("we," "our," or "us"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use the SurveyDex platform and related services (the "Service").
                                <br />
                                By accessing or using the Service, you consent to the practices described in this Privacy Policy. If you do not agree with this Privacy Policy, please refrain from using SurveyDex.
                            </p>
                            <strong>1. Information We Collect</strong>
                            <p>
                                1.1. Account Information: When you sign up for a SurveyDex account, we collect certain personally identifiable information, including your name, email address, and contact details.<br />
                                1.2. Survey Data: As part of using the Service, you may create and distribute surveys to collect responses from participants. We collect and store the survey responses and any associated data you provide through the Service.<br />
                                1.3. Usage Information: We collect information about your interactions with the Service, such as your IP address, device information, browser type, and operating system.<br />
                                1.4. Cookies and Similar Technologies: SurveyDex uses cookies and similar technologies to enhance your experience, analyze usage patterns, and provide personalized content. You can manage your cookie preferences through your browser settings.
                            </p>

                            <strong>2. How We Use Your Information</strong>
                            <p>
                                2.1. Providing the Service: We use the collected information to create and maintain your SurveyDex account, process your survey data, and provide customer support.<br />
                                2.2. Communication: We may use your contact information to send you important updates, notifications, and service-related announcements.<br />
                                2.3. Improvement of Service: Your feedback and survey data help us enhance and improve the Service, develop new features, and optimize user experience.<br />
                                2.4. Aggregated Data: We may use anonymized and aggregated data for statistical analysis, research, and marketing purposes.
                            </p>

                            <strong>3. Data Sharing and Disclosure</strong>
                            <p>
                                3.1. Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating the Service, such as hosting, data storage, analytics, and customer support services. These providers are bound by confidentiality agreements and are not permitted to use your information for any other purpose.<br />
                                3.2. Legal Compliance: We may disclose your information when required by applicable laws, regulations, legal processes, or government requests.<br />
                                3.3. Business Transfers: If there is a merger, acquisition, or other business transaction involving SurveyDex, your information may be transferred as part of the assets.
                            </p>
                            <strong>4. Data Security</strong>
                            <p>
                                4.1. We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.<br />
                                4.2. Despite our efforts, no data transmission over the internet or electronic storage method is entirely secure. We cannot guarantee absolute security, but we continually update and enhance our security practices to protect your data.
                            </p>
                            <strong>5. Your Choices</strong>
                            <p>
                                5.1. Account Information: You can review and update your account information by logging into your SurveyDex account.<br />
                                5.2. Marketing Communications: You have the option to opt-out of receiving marketing communications from us by following the unsubscribe instructions in the email or contacting us directly.
                            </p>
                            <strong>6. Children's Privacy</strong>
                            <p>
                                6.1. SurveyDex is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from children under 16. If you believe we have unintentionally collected information from a child under 16, please contact us immediately.
                            </p>
                            <strong>7. Changes to the Privacy Policy</strong>
                            <p>
                                7.1. We may update this Privacy Policy periodically. The most current version will be posted on our website, and the "Last updated" date will reflect the latest revision. Your continued use of SurveyDex after any changes to this Privacy Policy signifies your acceptance of the revised terms.
                            </p>

                            <br />
                            <p>
                                By using SurveyDex, you acknowledge that you have read and understood this Privacy Policy and agree to the collection and use of your information as described herein. If you do not agree with this Privacy Policy, please do not use the Service.
                                <br /><br />
                                Last updated: 1st June 2023
                            </p>
                        </div>
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default PrivacyPolicy;
