import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import api from "../../config/api";
import { number_format } from "../../components/Util";

class SurveyHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth_token: undefined,
            is_load: true,
            data: [],
            links: [],
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
            setTimeout(() => {
                this.getReports(api.survey_my_list);
            });
        }
    }

     /**
     * Get SurveyHistory
     */
     async getReports(URL) {
        let that = this;
        await axios({
            method: 'get',
            url: URL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + localStorage.getItem('auth_token'),
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                if (ress.data.data) {
                    that.setState({
                        data: ress.data.data.data,
                        links: ress.data.data.links,
                    })

                } else {
                    that.setState({
                        data: [],
                        links: [],
                    })
                }
            }
            else {
            }

            setTimeout(() => {
                that.setState({ is_load: false });
            });
        }).catch(function (e) {
            setTimeout(() => {
                that.setState({ is_load: false });
            });
        });
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="container">
                        <div className="bansub">
                            <div className="row">
                                <div className="col">
                                    <h1>Survey History</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="dashboard">
                    {!this.state.is_load ?
                    <>
                        <div className="table-responsive container">
                            <table className="table ">
                                <thead>
                                    <tr>
                                        <th>Date/Time</th>
                                        <th>Survey</th>
                                        <th>USDT</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.length>0?
                                            this.state.data.map((data, key) =>
                                                <tr key={"A" + 100 + key}>
                                                    <td>{data.created_at}</td>
                                                    <td>{data.survey.en_title}</td>
                                                    <td>{(data.amount > 0) ? "+" : ""}{number_format(data.amount,2,".",",")}</td>
                                                    <td>{"Completed"}</td>
                                                </tr>
                                            )
                                        :
                                        <tr>
                                            <td colSpan={3}>No Data Avaialble</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-2 d-flex justify-content-center">
                            <ul className="pagination">
                                {
                                    this.state.links.map((item, key) =>
                                        <li key={100 + key} className="page-item">
                                            <Link
                                                className={(item.active) ? "page-link active" : "page-link"}
                                                onClick={() => (item.url) ? this.getReports(item.url) : null}
                                            >
                                                {item.label}
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </>
                    : null}
                    </section>
                </>
                <Footer />
            </>
        );
    }
}
export default SurveyHistory;
